import React, { useState, useEffect } from 'react';
import { GeoJsonLayer } from '@deck.gl/layers';
import AppContext from './Context';
import { Auth } from 'aws-amplify';
import { amplifyAPITest, fetchHierarchyData, fetchUpdatedData } from './Api.js';

import { API } from 'aws-amplify';
import { arcgisToGeoJSON } from "@terraformer/arcgis";
import WedgeInputModal from '../Access Layer/WedgeInputModal';
import EditWedgeInputModal from '../Access Layer/EditWedgeInputModal';
import { IconLayer,  BitmapLayer } from '@deck.gl/layers';
import { TileLayer, MVTLayer } from '@deck.gl/geo-layers';// Import missing layers
import towerIcon from '../Icons/BlackTower.png';
import { loadImage } from '@loaders.gl/images';
import { fetch3GisLayer } from './3Gis';
import './Spinner.css';
import { EditableGeoJsonLayer, ViewMode, ModifyMode, DrawPolygonMode } from 'nebula.gl';
import { ContextMenu, MenuItem, ContextMenuTrigger, showMenu } from "react-contextmenu";
import { LineLayer } from '@deck.gl/layers';
import { DataFilterExtension } from '@deck.gl/extensions';


const ContextProvider = ({ children }) => {
  const [infoTable, setInfoTable] = useState([]);
const updateInfoTable = async () => {
  const newInfoTable = await fetchUpdatedData();
  setInfoTable(newInfoTable);
}

  const [editableInfoTable, setEditableInfoTable] = useState(infoTable);
  const [searchText, setSearchText] = useState('');
  
  // Connected to TapestryTool.js
  // Used in use effect in API data. API data populates this state variable
  const [listInfoTable, setlistInfoTable] = useState([]);
  
  // Connected to TapestryTool.js 
  // This state variable holds the initially pulled API data.
  const [apiData, setApiData] = useState(null);
  
  // Connected to TapestryTool.js
  // Holds layers for visibility from tree
  const [pointLayers, setPointLayers] = React.useState(new Map());
  const [lineLayers, setLineLayers] = React.useState(new Map());
  const [polygonLayers, setPolygonLayers] = React.useState(new Map());
  const [tooltipInfo, setTooltipInfo] = React.useState({ x: 0, y: 0, object: null, visible: false });
  const [hierarchyData, setHierarchyData] = React.useState({});
  const [mode, setMode] = useState(new ViewMode());
  const [selectedFeatureIndexes, setSelectedFeatureIndexes] = useState();
  const [isDrawing, setIsDrawing] = useState(false);
  const [isLoading, setIsLoading] = React.useState(true);
  const [clickedWedge, setClickedWedge] = React.useState(null)
  const [networkData, setNetworkData] = useState(null);
  
  
    const [layers, setLayers] = React.useState([]);
  const [layerVisibility, setLayerVisibility] = React.useState({
  'icon-layer-1': true,
  'icon-layer-2': true,
  'wms-tile-layer': true,
  "headroomLayer": true,
  "viewshedLayer": true,
  "editableLayer": true,
  'polygon-layer-1': true,
  'polygon-layer-2': true,
  'building-polygon-layer': false,
  'census-block-layer': false,
  'network-layer': false,
  'wedge-layer': false,
  'drawn-polygon-layer': true,
  'session-layer': false,
  '3d-line-layer': false,
  'reload-layer' : false,
  'focused-towers-layer': false,
  'focused-ap-layer': false,
  
  // Add other layers here
});


  const [selectedFeatures, setSelectedFeatures] = React.useState([]);
  const [wedgeTooltipInfo, setWedgetooltipInfo] = React.useState(null);
   const [towerData, setTowerData] = React.useState([]);
  
const [tooltipContent, setTooltipContent] = useState(null);
const [tooltipPosition, setTooltipPosition] = useState(null);
const [wedgeModalOpen, setWedgeModalOpen] = useState(false);

const [areasData, setAreasData] = useState(null);
const [fetchDashboardData, setFetchDashboardData] = useState(null);
const [rowData, setRowData] = useState([]);
const [submitWedgeHoverInfo, setSubmitWedgeHoverInfo] = useState(null);
const [selectedRow, setSelectedRow] = useState(null);
 const [polygonData, setPolygonData] = useState([]);


 const [isLoadingLayers, setIsLoadingLayers] = useState(false);
 const [selectedAttribute, setSelectedAttribute] = useState('marketing_headroom');
 const [heatmapScaleFactor, setHeatmapScaleFactor] = useState(1.0);
 

  const [selectedTower, setSelectedTower] = useState(null);
 const [savedWedges, setSavedWedges] = useState(new Map());
const [wedges, setWedges] = useState([]);
const [selectedWedge, setSelectedWedge] = useState(null);
const [contextMenu, setContextMenu] = useState({ visible: false, x: 0, y: 0 });
// const [drawnPolygons, setDrawnPolygons] = useState([]); // State to store drawn polygons
const [drawPolygonMode, setDrawPolygonMode] = useState(false); // State to enable or disable DrawPolygonMode
const [editableAccessLayer, setEditableAccessLayer] = useState();
const [selectedOption, setSelectedOption] = useState('allTarana');
 const [drawData, setDrawData] = useState({
    type: 'FeatureCollection',
    features: [],
  });
   const [accessMode, setAccessMode] = useState(new ViewMode());
   const [isAccessDrawing, setIsAccessDrawing] = useState(false);
const [isLassoModeEnabled, setIsLassoModeEnabled] = useState(false);
const [lassoData, setLassoData] = useState(null);
const [planId, setPlanId] = useState(null);
const [lineLayerVisible, setLineLayerVisible] = useState(false);
  const [lineData, setLineData] = useState([]);
  const [focusedTower, setFocusedTower] = useState(null);
  const [rightClickedTower, setRightClickedTower] = useState(null);
  const [clickedTowers, setClickedTowers] = useState(new Set());
const [focusedTowersData, setFocusedTowersData] = useState(null);

const [isWedgesLoading, setIsWedgesLoading] = useState(false);
const [focusedTowersList, setFocusedTowersList] = useState([]);
const [focusedApData, setFocusedApData] = useState(null);





const updateSelectedRow = (rowData) => {
  setSelectedRow(rowData);
};     

const onHover = ({ x, y, object }) => {
    if (object && object.properties) {
      let text = '';
      for (const [key, value] of Object.entries(object.properties)) {
        text += `${key}: ${value}\n`;
      }
      setTooltipInfo({ visible: true, x, y, text });
    } else {
      setTooltipInfo({ visible: false });
    }
  };

const drawPolygon = () => {
  setIsDrawing(true); 
  setMode(new DrawPolygonMode());
  setSelectedFeatureIndexes([]);
};

  const handleDeleteWedge = () => {
    if (clickedWedge && savedWedges.has(clickedWedge.properties.coveredBy)) {
        const updatedSavedWedges = new Map(savedWedges);
        updatedSavedWedges.delete(clickedWedge.properties.coveredBy);
        setSavedWedges(updatedSavedWedges);
        setClickedWedge(null); 
        console.log("Wedge deleted successfully");
         
    } else {
        console.error("No wedge selected or wedge does not exist");
    }
};

const handleEditClick = (x, y) => { // Pass x, y coordinates where the click happened
  // Check if a modal is already open

  setTooltipPosition({ x, y }); // Set the tooltip position

  setTooltipContent(
    <EditWedgeInputModal
      isOpen={wedgeModalOpen}
      clickedWedges={clickedWedge} // Pass the clickedWedges array
      isEditing={true}
      onSave={(newData) => {
        savedWedges.set(clickedWedge.properties.coveredBy, newData);
      }}
      onClose={() => setTooltipContent(null)}
      style={{ position: 'absolute', left: x, top: y }} 
    />
  );
};
useEffect(() => {
  const lineLayer = new GeoJsonLayer({
    id: '3d-line-layer',
    data: lineData,
    getWidth: 100,
    getColor: [255, 0, 0, 255],
    widthMinPixels: 10,
    visible: true,
    pickable: true,
  });

  console.log("Adding Line Layer", lineLayer); // Debugging line

  setLayers((prevLayers) => [...prevLayers.filter(layer => layer.id !== '3d-line-layer'), lineLayer]);
}, [lineData]);


const fetchFocusedTowersData = async (towers) => {
    try {
      const response = await API.post('tapestryGeo', '/accesstool/focustowers', { body: focusedTowersList });
      console.log(response);
      return response;
    } catch (error) {
      console.error('Error fetching focused towers data:', error);
      return null;
    }
  };

 const focusOnTower = async () => {
  if (rightClickedTower) {
    console.log("Right clicked tower:", rightClickedTower); // Log the tower that was right-clicked

    const newFocusedTower = { site: rightClickedTower.site, network: rightClickedTower.network };
    console.log("New focused tower being added:", newFocusedTower); // Log the new focused tower details

    const towers = [`${rightClickedTower.network}_${rightClickedTower.site}`];
    console.log("Towers array for API call:", towers); // Log the towers array being sent to the API

    try {
      
      setFocusedTowersList(prevList => {
        const prevTowerSet = new Set(prevList)
        const toggleTower = `${rightClickedTower.network}_${rightClickedTower.site}`
        if (prevTowerSet.has(toggleTower)){
          prevTowerSet.delete(toggleTower)
        }
        else {
          prevTowerSet.add(toggleTower)
        }
        
        const updatedList = [...prevTowerSet];
        console.log("Updated focused towers list:", updatedList); // Log the updated list of focused towers
        return updatedList;
      });
      // const geoJsonData = await fetchFocusedTowersData(towers);
      
      // console.log("GeoJson data received for focused tower:", geoJsonData); // Log the received GeoJson data

      // Update the focused towers list with the new tower
      
    } catch (error) {
      console.error("Error in focusing on tower:", error); // Log any errors that occur during the process
    }
  } else {
    console.log("No tower was right-clicked or selected."); // Log if no tower was right-clicked
  }
};
const removeFocusedTower = (towerIdentifier) => {
  setFocusedTowersList(prevList => {
    return prevList.filter(t => t !== towerIdentifier);
  });
};


useEffect(() => {
  const fetchAndSetFocusedTowersData = async () => {
    try {
      const geoJsonData = await fetchFocusedTowersData(focusedTowersList);
      console.log("GeoJson data received for focused towers:", geoJsonData);
      setFocusedTowersData(geoJsonData); // Update the focusedTowersData state

      // Set the layer visibility here after the data is fetched
      if (geoJsonData) {
        if (!layerVisibility['focused-towers-layer']){
          toggleLayerVisibility('focused-towers-layer')
          toggleLayerVisibility('focused-ap-layer')
        }
        else {
          toggleLayerVisibility('focused-towers-layer')
          toggleLayerVisibility('focused-ap-layer')
          toggleLayerVisibility('focused-towers-layer')
          toggleLayerVisibility('focused-ap-layer')
        }
      }
    } catch (error) {
      console.error("Error fetching focused towers data:", error);
    }
  };

  if (focusedTowersList.length > 0) {
    fetchAndSetFocusedTowersData();
  }
}, [focusedTowersList]);


const handleOptionChange = (event) => {
  setSelectedOption(event.target.value);
};

  
const toggleEditMode = () => {
  setIsAccessDrawing(true)
  if (mode instanceof ViewMode) {
    setMode(new ModifyMode());
  } else {
    setMode(new ViewMode());
  }
  
};

const toggleAccessMode = () => {
  const isEnablingLasso = accessMode instanceof ViewMode;

  // Toggle the drawPolygonMode state.
  setDrawPolygonMode(!drawPolygonMode);

  // Set the access mode and layer visibility accordingly.
  setAccessMode(isEnablingLasso ? new DrawPolygonMode() : new ViewMode());
  setLayerVisibility(prevVisibility => ({
    ...prevVisibility,
    'drawn-polygon-layer': isEnablingLasso
  }));
};

const isSelected = (object, index) => {
  return selectedFeatureIndexes.includes(index);
}

const handleWedgeSubmit = (submittedWedges) => {
    setWedges(submittedWedges);
  };
  
const handleSaveWedges = (newWedges) => {
  
if (selectedTower != null) {
  newWedges.map((thisWedge) => {
    thisWedge["tower"] = selectedTower
    savedWedges.set(selectedTower+"_"+thisWedge.sectorName,thisWedge)
  })
}
};

useEffect(() => {
  console.log(savedWedges);
}, [savedWedges]);


const handleHover = ({ object, x, y }) => {
    if (object && object.properties) {
      const inobject = object.properties;
      setWedgetooltipInfo({ inobject, x, y });
    } else {
      const inobject = object;
      setWedgetooltipInfo({ inobject, x, y });
    }
  };
  
  const createPointLayer = (points, layerId) => {
    return new GeoJsonLayer({
      id: `point-layer-${layerId}`,
      data: {
        type: "FeatureCollection",
        features: points,
      },
      pickable: true,
      stroked: false,
      filled: true,
      radiusScale: 6,
      pointRadiusMinPixels: 10,
      pointRadiusMaxPixels: 10,
      getFillColor: [255, 0, 0, 255],
      getLineColor: [0, 0, 0, 255],
      onHover: onHover,
    });
  };

  const createLineLayer = (lines, layerId) => {
    return new GeoJsonLayer({
      id: `line-layer-${layerId}`,
      data: {
        type: "FeatureCollection",
        features: lines,
      },
      pickable: true,
      stroked: true,
      filled: false,
      lineWidthMinPixels: 1,
      getLineColor: [0, 0, 255, 255],
      getLineWidth: 1,
      onHover: onHover,
    });
  };
 

const createPolygonLayer = (polygons, layerId) => {
  return new EditableGeoJsonLayer({
    id: `polygon-layer-${layerId}`,
    data: {
      type: "FeatureCollection",
      features: polygons,
    },
    mode: new ViewMode(),
    pickable: true,
    stroked: true,
    filled: true,
    lineWidthMinPixels: 1,
    getLineColor: [0, 0, 139, 255], 
    getFillColor: [173, 216, 230, 100], 
    getLineWidth: 1,
    onHover: onHover,
  });
};
  
  const getColorByAttribute = (object) => {
  const value = object.properties[selectedAttribute]*heatmapScaleFactor;
  const alpha = 128;

  if (selectedAttribute === 'marketing_headroom') {
    // Color scheme for marketing_headroom
  if (value > 0 && value < 1) return [255, 255, 224, alpha]; // Light Yellow
  if (value >= 1  && value < 2) return [255, 237, 160, alpha]; // Lighter Orange
  if (value >= 2  && value < 5) return [254, 217, 118, alpha]; // Light Orange
  if (value >= 5  && value < 10) return [254, 178, 76, alpha];  // Orange
  if (value >= 10  && value < 20) return [253, 141, 60, alpha];  // Darker Orange
  if (value >= 20) return [227, 26, 28, alpha];  // Dark Orange
    // ... other conditions
  } else if (selectedAttribute === 'hu_100') {
  if (value > 0 && value < 1) return [255, 255, 224, alpha]; // Light Yellow
  if (value >= 1  && value < 2) return [255, 237, 160, alpha]; // Lighter Orange
  if (value >= 2  && value < 5) return [254, 217, 118, alpha]; // Light Orange
  if (value >= 5  && value < 10) return [254, 178, 76, alpha];  // Orange
  if (value >= 10  && value < 20) return [253, 141, 60, alpha];  // Darker Orange
  if (value >= 20) return [227, 26, 28, alpha];  // Dark Orange
    // Color scheme for hu_100
    // ... conditions
  } else if (selectedAttribute === 'hu_500') {
  if (value > 0 && value < 1) return [255, 255, 224, alpha]; // Light Yellow
  if (value >= 1  && value < 2) return [255, 237, 160, alpha]; // Lighter Orange
  if (value >= 2  && value < 5) return [254, 217, 118, alpha]; // Light Orange
  if (value >= 5  && value < 10) return [254, 178, 76, alpha];  // Orange
  if (value >= 10  && value < 20) return [253, 141, 60, alpha];  // Darker Orange
  if (value >= 20) return [227, 26, 28, alpha];  // Dark Orange
    // Color scheme for hu_500
    // ... conditions
  } else if (selectedAttribute === 'subs') {
  if (value > 0 && value < 1) return [255, 255, 224, alpha]; // Light Yellow
  if (value >= 1  && value < 2) return [255, 237, 160, alpha]; // Lighter Orange
  if (value >= 2  && value < 5) return [254, 217, 118, alpha]; // Light Orange
  if (value >= 5  && value < 10) return [254, 178, 76, alpha];  // Orange
  if (value >= 10  && value < 20) return [253, 141, 60, alpha];  // Darker Orange
  if (value >= 20) return [227, 26, 28, alpha];  // Dark Orange
    // Color scheme for subs
    // ... conditions
  }

  return [0, 0, 0, 0]; // Default color
};

 const stringToColor = (str) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  
  let r = (hash & 0xFF0000) >> 16;
  let g = (hash & 0x00FF00) >> 8;
  let b = hash & 0x0000FF;

  r = Math.min(255, r + 50);
  g = Math.min(255, g + 50);
  b = Math.min(255, b + 50);

  return [r, g, b];
}

const createEditableLayer = (drawData, accessMode, setDrawData) => {
  return new EditableGeoJsonLayer({
    id: 'drawn-polygon-layer',
    data: drawData,
    visible: layerVisibility['drawn-polygon-layer'],
    mode: accessMode,
    selectedFeatureIndexes: [0],
    pickable: true,
    onEdit: ({ updatedData, editType, featureIndexes}) => {
      // console.log('EditType:', editType);
      // console.log('FeatureIndexes:', featureIndexes);
      // console.log('UpdatedData:', updatedData);
      if (editType === 'addFeature' && updatedData.features.length > 0) {
        updatedData.features = updatedData.features.slice(-1)
        setDrawData(updatedData);
        setIsAccessDrawing(false);
        console.log('New Draw Data:', updatedData);
        toggleLayerVisibility('drawn-polygon-layer')
        toggleLayerVisibility('drawn-polygon-layer')
        // You can call toggleAccessMode() here if needed
        // toggleAccessMode();
      }
    },
    // Add any additional props here...
  });
};


const fetchDataAndUpdateLayers = async (layerVisibility, showLayer) => {
  
  
  const iconLayer2Data = [];
  // Existing layers
  const towerLayer1 = createTowerLayer(towerData, 'tower-layer-1', layerVisibility['tower-layer-1']);
  towerLayer1.onClick = handleTowerClick;
  const iconLayer2 = createIconLayer2(iconLayer2Data, 'icon-layer-2', layerVisibility['icon-layer-2']);
  const tileLayer = createTileLayer('https://coverage.risebroadband.com/arcgis/services/HeadroomRasterHeatmap/MapServer/WmsServer','headroom-layer',layerVisibility['headroom-layer']);
  const wedgeLayer = createWedgeLayer(wedges, 'wedge-layer', layerVisibility['wedge-layer']);
  const newEditableLayer = createEditableLayer(drawData, accessMode, setDrawData);
 const existingSessionLayer = layers.find(layer => layer.id.startsWith('session-layer-'));
  
// const viewshedLayer = new GeoJsonLayer({
//   id: 'viewshed-layer',
//   data: lineData,
//   stroked: true, // to draw lines
//   filled: false, // no fill for LineString features
//   lineWidthMinPixels: 5, // minimum line width in pixels
//   getLineColor: (f) => {
//     const towerName = f.properties.towername;
//     if (towerName) {
//       return stringToColor(towerName);
//     } else {
//       return [0, 255, 255, 200]; // Default color with alpha if towername is not available
//     }
//   }, // red color for lines
//   lineWidth: 10, // width of the line
//   visible: layerVisibility['viewshed-layer'],
//   pickable: true,
//   onHover: handleHover
// });
const viewshedLayer = createTileLayer('https://coverage.risebroadband.com:6443/arcgis/services/ap_wedge_viewsheds_2018_v2/ImageServer/WMSServer','viewshed-layer',layerVisibility['viewshed-layer']);
// const wedgeLayer = createWedgeLayer();
  // New layers
  
 
const buildingPolygonLayer = new MVTLayer({
  id: 'building-polygon-layer',
  data: 'https://martin.rbtapestrytools.com:3000/tapestry_hu/{z}/{x}/{y}',
  // data: 'https://martin.rbtapestrytools.com:3000/wireless_network/{z}/{x}/{y}',
  pickable: true,
  getFillColor: (f) => {
    const towerName = f.properties.towername;
    if (towerName) {
      return stringToColor(towerName);
    } else {
      return [0, 255, 255, 200]; // Default color with alpha if towername is not available
    }
  },
  getLineColor: [255, 0, 0,50],
  getRadius: 5,
  getLineWidth: 0.5,
  pointRadiusUnits: 'pixels',
  onDataLoad: () => {},
  visible: layerVisibility['building-polygon-layer'],
  onHover: handleHover,
  minZoom: 7,
  maxZoom: 23,
  getElevation: (f) => {
    return f.properties.coverage_percentage / 10;
  },
  extruded: true,
  wireframe: true,
  // elevationScale: 0.01
    
  
});


  const censusBlockLayer = new MVTLayer({
  id: 'census-block-layer',
  data: 'https://martin.rbtapestrytools.com:3000/census_blocks_2020/{z}/{x}/{y}',
  pickable: true,
  getFillColor: getColorByAttribute,
  getLineColor: [0, 0, 0],
  getLineWidth: 1, 
  lineWidthUnits: 'pixels', 
  getRadius: 5,
  pointRadiusUnits: 'pixels',
  onDataLoad: () => {},
  visible: layerVisibility['census-block-layer'],
  onHover: handleHover,
  minZoom: 7, // Layer will be visible starting at zoom level 0
  maxZoom: 23,  // Layer will be visible up to zoom level 10
  updateTriggers: {
    getFillColor: [selectedAttribute,heatmapScaleFactor]
  }
});

const isNetworkLayerVisible = !focusedTower;

 const networkLayer = new MVTLayer({
    id: 'network-layer',
    data: 'https://martin.rbtapestrytools.com:3000/wireless_network/{z}/{x}/{y}',
    visible: layerVisibility['network-layer'],
    pickable: true,
    stroked: true,
    filled: true,
    extruded: false,
    lineWidthScale: 20,
    lineWidthMinPixels: 1, 
    binary:false,
    extensions: [new DataFilterExtension({ filterSize: 1 })],
  getFilterValue: (feature) => {
    // Apply the filter only when focusedTower is set
    if (focusedTower) {
      return (feature.properties.site === focusedTower.site && feature.properties.network === focusedTower.network) ? 1 : 0;
    }
    // By default, show all features
    return 1;
  },
  filterRange: [1, 1],
    getFillColor: (feature) => {
      const speedStr = feature.properties.max_speed;
      const speed = speedStr ? parseInt(speedStr.replace("MB", "")) : 0;
      if (speed === 0) {
        return [255, 0, 0, 100]; 
      } else if (speed > 0 && speed <= 99) {
        return [255, 255, 0, 100]; 
      } else if (speed === 100) {
        return [0, 255, 0, 100];
      } else {
        return [0, 0, 255, 100]; 
      }
    },
    getLineColor: [0, 0, 0, 100],
    getRadius: 100,
    getLineWidth: 1, 
    getElevation: 30,
    onHover: handleHover,
  });
  
const reloadLayer = new MVTLayer({
  id: 'reload-layer',
  data: 'https://martin.rbtapestrytools.com:3000/visibility_lines/{z}/{x}/{y}',
  visible: layerVisibility['reload-layer'],
  pickable: true,
  stroked: true,
  filled: true,
  extruded: false,
  lineWidthScale: .01, // Reduced scale for thinner lines
  lineWidthMinPixels: 1,
  getFillColor: () => [10, 186, 181, 255], // Tiffany Blue in RGBA
  getLineColor: [10, 186, 181, 255], // Lighter gray color for lines
  getRadius: 100,
  getLineWidth: (feature) => 2, // Reduced line width
  getElevation: (feature) => feature.properties.yourZProperty || 0, // Use a property for elevation if available
  onHover: handleHover,
});
const focusedTowersLayer = new GeoJsonLayer({
      id: 'focused-towers-layer',
      data: focusedTowersData,
        pickable: true,
        stroked: true,
        filled: true,
        visible: layerVisibility['focused-towers-layer'],
        lineWidthScale: 20,
        lineWidthMinPixels: 1,
        highlightColor: [255, 255, 0, 200],
        getFillColor: (feature) => {
          // Apply the same color scheme as the network layer
          const speedStr = feature.properties.max_speed;
          const speed = speedStr ? parseInt(speedStr.replace("MB", "")) : 0;
          if (speed === 0) return [255, 0, 0, 100];
          else if (speed > 0 && speed <= 99) return [255, 255, 0, 100];
          else if (speed === 100) return [0, 255, 0, 100];
          else return [0, 0, 255, 100];
        },
        getLineColor: [0, 0, 0, 100],
        getLineWidth: 1,
        getElevation: 30,
        onHover: handleHover,
        onClick: (info, event) => {
          console.log(event)
      const currentTime = Date.now();
      console.log(currentTime)
      console.log(lastClickTime)
      if (currentTime - lastClickTime < DOUBLE_CLICK_THRESHOLD) {
        console.log('doubleClickDetected')
        // Double click detected
        handleWedgeDoubleClick(info.object);
      }
      lastClickTime = currentTime;
    }
      });

 const focusedApLayer = new GeoJsonLayer({
    id: 'focused-ap-layer',
    data: focusedApData, 
    pickable: true,
    stroked: true,
    filled: true,
    lineWidthUnits: 'pixels',
    lineWidthScale: 10, 
    getLineColor: [153, 102, 255, 255], 
    getLineWidth: d => d.geometry.type === 'LineString' ? 0.5 : 1, 
    getFillColor: [255, 0, 0, 255], 
    getRadius: d => d.geometry.type === 'Point' ? 0.5 : 1, 
    pointRadiusUnits: 'pixels',
    pointRadiusScale: 10, 
    onHover: handleHover,
    visible: layerVisibility['focused-ap-layer'],
});

  
  const allLayers = [
    focusedTowersLayer,
    iconLayer2, 
    tileLayer, 
    networkLayer, 
    viewshedLayer, 
    censusBlockLayer, 
    buildingPolygonLayer, 
    towerLayer1, 
    wedgeLayer, 
    newEditableLayer,
    reloadLayer,
    focusedApLayer
  ];

  // Add the session layer back if it exists
  if (existingSessionLayer) {
    allLayers.push(existingSessionLayer);
  }

  // Update the layers state
  setLayers(allLayers);
};


const toggleLayerVisibility = (layerId) => {
  setLayerVisibility((prevVisibility) => ({
    ...prevVisibility,
    [layerId]: !prevVisibility[layerId],
  }));

  setLayers((prevLayers) => prevLayers.map((layer) => {
    if (layer.id === layerId && !layer.id.startsWith('session-layer-')) {
      return { ...layer, visible: !layer.visible };
    }
    return layer;
  }));
};




const DOUBLE_CLICK_THRESHOLD = 500; 
let lastClickTime = null;



const handleTowerClick = (info, event) => {
  const currentTime = new Date().getTime();
   const towerIdentifier = `${info.object.network}_${info.object.site}`;
  console.log(event)
  if (event.rightButton) { // Right-click
    console.log('Right-click detected on tower:', info.object);
    setRightClickedTower({network:info.object.network,site:info.object.site});
    showMenu({
      id: "wedge-context-menu",
      position: { x: event.center.x, y: event.center.y }
    });
  } else if (lastClickTime && (currentTime - lastClickTime) < DOUBLE_CLICK_THRESHOLD) { // Double click
    setSelectedTower(`${info.object.network}_${info.object.site}`);
    setTooltipContent(
      <WedgeInputModal
        isOpen={wedgeModalOpen}
        onClose={() => setTooltipContent(null)}
      />
    );
    setTooltipPosition({ x: info.x, y: info.y });
  }
  setClickedTowers(prevClickedTowers => new Set(prevClickedTowers).add(towerIdentifier));

    lastClickTime = currentTime;
  };



const createTowerLayer = (data, layerId, visible) => {
  const towerDataURL = "/tower-cell-solid.svg";
  const ICON_MAPPING = {
    marker: {x: 0, y: 0, width: 100, height: 100, mask: true}
  };

  return new IconLayer({
    id: layerId,
    data: data,
    visible: visible,
    onHover: handleHover,
    onClick: handleTowerClick,
    onContextMenu: handleTowerRightClick,
    pickable: true,
    iconAtlas: towerIcon,
    iconMapping: ICON_MAPPING,
    getIcon: d => 'marker',
    sizeScale: 15,
    getPosition: d => d.coordinates,
    getSize: d => 5,
    getColor: (f) => {
      const towerName = f.network+'_'+f.site;
      if (f['data available'] === "True") {
        return stringToColor(towerName);
      } else {
        return [200, 0, 0, 64]; 
      }
    },
  });
};

const handleTowerRightClick = (info, event) => {
    event.preventDefault(); 
    console.log('right click fired')
    if (info.object) {
      console.log(info.object)
      setRightClickedTower(info.object); 
      // showMenu({
      //   id: "wedge-context-menu",
      //   position: { x: event.clientX, y: event.clientY }
      // });
    }
  };


// const focusOnTower = async () => {
//   if (rightClickedTower) {
//     const newFocusedTower = { site: rightClickedTower.site, network: rightClickedTower.network };
//     setFocusedTower(newFocusedTower); // Assuming setFocusedTower is your existing state update function

//     const towers = [`${rightClickedTower.network}_${rightClickedTower.site}`];
//     const geoJsonData = await fetchFocusedTowersData(towers); // Fetch the data for the focused tower

//     // Update the focused towers list with the new tower
//     setFocusedTowersList(prevList => [...prevList, { ...newFocusedTower, data: geoJsonData }]);
//   }
// };

  const createTileLayer = (baseURL,layerID,visible) => {
  return new TileLayer({
    id: layerID,
    visible: visible,
    tileSize: 256,
    minZoom: 7,
    maxZoom: 23,
    autoHighlight: true,
    highlightColor: [60, 60, 60, 40],
    onError: console.error,

    getTileData: async ({ bbox, width, height }) => {
      const url = getWMSUrl([bbox.west,bbox.south,bbox.east,bbox.north].join(','), 1000, 1000, baseURL);
      const image = await loadImage(url);
      return {
        data: image,
      };
    },

    renderSubLayers: (props) => {
      const {
        data,
        bbox: { west, south, east, north },
      } = props.tile;
      
      return new BitmapLayer(props, {
        image: data,
        bounds: [west, south, east, north]
        // tintColor: [203, 22, 219]
      });
    },
  });
};

const fetchPolygonAndTowerData = async () => {
  setIsLoadingLayers(false);
  try {
    const infoTable = await API.get("tapestryGeo", "/geoInfo");
    const promises = infoTable.filter((element) => element.source_table === 'wireless_por_info').map((element) =>
      API.get("tapestryGeo", "/geoData", {
        queryStringParameters: {
          id: element.id.toString(),
          source_table: element.source_table,
        },
      })
        .then((response) => ({
          id: element.id.toString().concat(" ", element.source_table),
          response: response,
        }))
    );

    const results = await Promise.all(promises);
    console.log(results);
    const polygons = [];
    
    results.forEach(({id, response}) => {
        response.features.forEach((item) => {
            polygons.push(item);
        });
    });
    
    const polygonFC = {features: polygons, type: "FeatureCollection"};
    
    console.log(polygonFC)
    
    setPolygonData(polygonFC)
    if (polygons.length === 0) {
        console.error(`No polygons found.`);
    }
    const towerLayer1Data = await API.get("tapestryGeo", "/networkData/siteInfo");
    setTowerData(towerLayer1Data);
    setIsLoadingLayers(false);
  } catch (err) {
    console.error(err);
  }
};

const createIconLayer2 = (data, layerId, visible) => {
  return new IconLayer({
    id: layerId,
    data,
    pickable: true,
    visible,
    // ... other layer properties
    onHover: handleHover,
  });
};
const getWMSUrl = (bbox, width, height, baseUrl) => {
  //const baseUrl = 'https://coverage.risebroadband.com/arcgis/services/HeadroomRasterHeatmap/MapServer/WmsServer'; // Replace with your WMS server URL
  const layer = '0'; // Replace with your layer name
  const version = '1.1.1'; // Replace with your desired WMS version
  const srs = 'EPSG:4326';
  const format = 'image/png'; // Replace with the desired format
  const styles = ''; // Replace with the desired styles or leave empty for default
  
  const url = `${baseUrl}?SERVICE=WMS&VERSION=${version}&REQUEST=GetMap&LAYERS=${layer}&STYLES=${styles}&SRS=${srs}&WIDTH=${width}&HEIGHT=${height}&FORMAT=${format}&BBOX=${bbox}&TRANSPARENT=True`

  return url;
};

// Get Dashboard data
const fetchDashboardDataFunc = async () => {
  try {
      
      
      const tasks = areasData.features.map(async (element)  =>
      {
        const thisGeom = {
        "hasZ": false,
        "hasM": false,
        "spatialReference":areasData.spatialReference,
        "rings":element.geometry.rings
      }
      
      
      const hhpQueryBase = {
        where: "OBJECTID IS NOT NULL",
        geometry: thisGeom,
        geometryType: "esriGeometryPolygon",
        spatialRel: "esriSpatialRelIntersects",
        units: "esriSRUnit_Foot",
        returnGeometry: false,
        returnTrueCureves: false,
        returnIDsOnly: false,
        returnCountOnly: false,
        returnIdsOnly: false,
        returnCountOnly: false,
        outStatistics: [{"statisticType":"COUNT","onStatisticField": "objectid","outStatisticFieldName":"HHPCount"}],
        returnZ: false,
        returnM: false,
        returnDistinctValues: false,
        returnExtentOnly:false,
        featureEncoding: "esriDefault",
        f: "json"
      }
      
      let urlSearchParams = new URLSearchParams();

      for (const [key, value] of Object.entries(hhpQueryBase)) {
        if (typeof value === 'object') {
          urlSearchParams.append(key, JSON.stringify(value));
        } else {
          urlSearchParams.append(key, value);
        }
      }

    const hhpQueryBaseUrlEncoded = urlSearchParams.toString();

    const HHPresponse = await fetch("https://arcgis.risebroadband.com/arcgis/rest/services/3GIS/Telecom/MapServer/14/query", {
      method: 'post',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: hhpQueryBaseUrlEncoded
    });

    if (!HHPresponse.ok) {
      throw new Error(`HTTP error! status: ${HHPresponse.status}`);
    }
      const thisResponse = await HHPresponse.json()
      
      
      const spanQueryBase = {
        where: "OBJECTID IS NOT NULL",
        geometry: thisGeom,
        geometryType: "esriGeometryPolygon",
        spatialRel: "esriSpatialRelIntersects",
        units: "esriSRUnit_Foot",
        returnGeometry: false,
        returnTrueCureves: false,
        returnIDsOnly: false,
        returnCountOnly: false,
        returnIdsOnly: false,
        returnCountOnly: false,
        outStatistics: [{"statisticType":"SUM","onStatisticField": "measuredlength","outStatisticFieldName":"spanLength"}],
        returnZ: false,
        returnM: false,
        returnDistinctValues: false,
        returnExtentOnly:false,
        featureEncoding: "esriDefault",
        f: "json"
      }
      
      urlSearchParams = new URLSearchParams();

      for (const [key, value] of Object.entries(spanQueryBase)) {
        if (typeof value === 'object') {
          urlSearchParams.append(key, JSON.stringify(value));
        } else {
          urlSearchParams.append(key, value);
        }
      }

    const spanQueryBaseUrlEncoded = urlSearchParams.toString();

    const spanresponse = await fetch("https://arcgis.risebroadband.com/arcgis/rest/services/3GIS/Telecom/MapServer/12/query", {
      method: 'post',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: spanQueryBaseUrlEncoded
    });

    if (!spanresponse.ok) {
      throw new Error(`HTTP error! status: ${spanresponse.status}`);
    }
      const thisSpanResponse = await spanresponse.json()
      
      return {
          areaName: element.attributes.areaname,
          hhp: thisResponse.features[0].attributes.hhpcount,
          path: thisSpanResponse.features[0].attributes.spanlength
        }
      })
      
    const results = await Promise.all(tasks)
    
    await setFetchDashboardData(results);
    
    console.log(results)
      

  } catch (err) {
    console.error(err.message);
  }
};

  const fetchAreas = async () => {
    try {
      const areasURL = 'https://arcgis.risebroadband.com/arcgis/rest/services/3GIS/Telecom/MapServer/16/query?where=OBJECTID+IS+NOT+NULL&f=json';
      const response = await fetch(areasURL, { method: 'get' });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const areasResult = await response.json();
      await setAreasData(areasResult);
    } catch (error) {
      console.error(error);
    }
  };



const fetch3GisGeo =async () => {
  try{
    // need to add areas to the state
    
    const tasks = areasData.features.map(async (element)  =>
      {
        const thisGeom = {
        "hasZ": false,
        "hasM": false,
        "spatialReference":areasData.spatialReference,
        "rings":element.geometry.rings
      }
      
      
      const spanQueryBase = {
        where: "OBJECTID IS NOT NULL",
        geometry: thisGeom,
        geometryType: "esriGeometryPolygon",
        spatialRel: "esriSpatialRelIntersects",
        units: "esriSRUnit_Foot",
        outFields: "inventory_status_code, install_method, diameter, measuredlength, rbid, material_cd, mat_description, marketid, ductcount",
        returnGeometry: true,
        returnTrueCureves: false,
        outSR: 4326,
        returnIDsOnly: false,
        returnCountOnly: false,
        returnIdsOnly: false,
        returnCountOnly: false,
        returnZ: false,
        returnM: false,
        returnDistinctValues: false,
        returnExtentOnly:false,
        featureEncoding: "esriDefault",
        f: "geojson"
      }
      
      let urlSearchParams = new URLSearchParams();

      for (const [key, value] of Object.entries(spanQueryBase)) {
        if (typeof value === 'object') {
          urlSearchParams.append(key, JSON.stringify(value));
        } else {
          urlSearchParams.append(key, value);
        }
      }

    const spanQueryBaseUrlEncoded = urlSearchParams.toString();

    const spanresponse = await fetch("https://arcgis.risebroadband.com/arcgis/rest/services/3GIS/Telecom/MapServer/12/query", {
      method: 'post',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: spanQueryBaseUrlEncoded
    });

    if (!spanresponse.ok) {
      throw new Error(`HTTP error! status: ${spanresponse.status}`);
    }
    
    
    return spanresponse.json()
    
      })
    
    const results = await Promise.all(tasks)
    
    console.log(results)
      
  } catch (err) {
    console.error(err.message);
  }
};

useEffect(() => {
    fetchAreas();
  }, []);

  useEffect(() => {
    if (areasData) {
      fetchDashboardDataFunc();
      // fetch3GisGeo();
    }
  }, [areasData]); 
  
  // useEffect(() => {
  //   populateLassoWedges();
  // }, [drawData]);


 const populateWedges = async () => {
  setIsWedgesLoading(true);
  if (drawPolygonMode ) {
 
    await populateLassoWedges();
  } else {
  
  const wedgeParams = [];
  for (let value of savedWedges.values()){
    const thisParam =  {
      Tower: value.tower,
      Sector_Name: value.sectorName,
      Tech: value.tech,
      Priority: value.priority,
      Radius_Override: value.radiusOverride === "" ? null : value.radiusOverride,
      Azimuth: Number(value.azimuth),
      Beamwidth: Number(value.beamWidth),
    };
    
    wedgeParams.push(thisParam);
  };

  const submitWedges = async () => {
    try {
      const username = await Auth.currentAuthenticatedUser().then((user) => {
        return user.attributes.email;
      });

      const response = await API.post('tapestryGeo', '/accesstool/coveragecalc', {
        body: wedgeParams,
        queryStringParameters: {"username": username},
        timeout: 60000,
      });
      
      const geoJsonData = response.geojson;
      
      if (response && response.planid) {
          setPlanId(response.planid);
      }
          
      setRowData(Object.values(geoJsonData.features.map((feature) => feature.properties)));

      const newLayer = new GeoJsonLayer({
        id: `wedge-layer`,
        data: geoJsonData,
        filled: true,
        stroked: true,
        pickable: true,
        lineWidthUnits: 'pixels',
        lineWidthScale: 1,
        getFillColor: [0, 255, 0, 100],
        getLineColor: [0, 0, 0, 255],
        getLineWidth: 1,
        onHover: handleHover,
        onClick: (info, event) => { 
          if (event.rightButton) {
            console.log(info);
            setClickedWedge(info.object);
            setSelectedTower(info.object.properties.Tower);
          }
        }
      });

      setLayers((prevLayers) => {
        const updatedLayers = prevLayers.filter(
          (prevLayer) => prevLayer.id !== newLayer.id
        );
        updatedLayers.push(newLayer);
        return updatedLayers;
      });

      setWedges(geoJsonData);

      // Toggle the visibility of the wedge layer to true
      setLayerVisibility((prevVisibility) => {
        return {
          ...prevVisibility,
          'wedge-layer': true,
        };
      });

} catch (error) {
    console.error('Error populating wedges:', error);
  } finally {
    setIsWedgesLoading(false); // Stop loading regardless of success or failure
  }
};

  const retrySubmitWedges = async (retries = 3) => {
    for (let i = 0; i < retries; i++) {
      try {
        await submitWedges();
        return;
      } catch (error) {
        console.error(`Attempt ${i + 1} failed. Retrying...`);
      }
    }
    console.error('Max retries reached. Could not populate wedges.');
  };

  retrySubmitWedges();

  setWedges(null);
  setWedgeModalOpen(false);
}
}


 const populateLassoWedges = async () => {
   setIsWedgesLoading(true);
    const lassoSend = {
      type: "featureCollection",
      features: drawData.features[0]
    }
 const wedgeParams = [];
  for (let value of savedWedges.values()){
    const thisParam =  {
      Tower: value.tower,
      Sector_Name: value.sectorName,
      Tech: value.tech,
      Priority: value.priority,
      Radius_Override: value.radiusOverride === "" ? null : value.radiusOverride,
      Azimuth: Number(value.azimuth),
      Beamwidth: Number(value.beamWidth),
    };
    
    wedgeParams.push(thisParam);
  };

  const submitWedges = async () => {
    try {
      const username = await Auth.currentAuthenticatedUser().then((user) => {
        return user.attributes.email;
      });

      const response = await API.post('tapestryGeo', '/accesstool/networklasso', {
        body: {lasso:lassoSend,option:selectedOption, "wedgeParams":wedgeParams},
        queryStringParameters: {"username": username},
        timeout: 60000,
      });

      const geoJsonData = response.geojson;
        if (response && response.planid) {
          setPlanId(response.planid);
      }
      
      console.log("GeoJsonData",geoJsonData)
      geoJsonData.features.map((feature) => {
        
        const thisWedge = { azimuth: feature.properties.Azimuth, beamWidth: feature.properties.Beamwidth, 
                            sectorName: feature.properties["Sector Name"], tech: feature.properties.Tech, 
                            priority: feature.properties.Priority, radiusOverride: feature.properties["Radius Override"],
                            tower: feature.properties.Tower}
        
        savedWedges.set(feature.properties.coveredBy,thisWedge)
        
      })
      
      console.log("Saved wedges in lasso",savedWedges)
      
      setRowData(Object.values(geoJsonData.features.map((feature) => feature.properties)));

      const newLayer = new GeoJsonLayer({
        id: `wedge-layer`,
        data: geoJsonData,
        filled: true,
        stroked: true,
        pickable: true,
        lineWidthUnits: 'pixels',
        lineWidthScale: 1,
        getFillColor: [0, 255, 0, 100],
        getLineColor: [0, 0, 0, 255],
        getLineWidth: 1,
        onHover: handleHover,
        onClick: (info, event) => { 
          if (event.rightButton) {
            console.log(info);
            setClickedWedge(info.object);
            setSelectedTower(info.object.properties.Tower);
          }
        }
      });

      setLayers((prevLayers) => {
        const updatedLayers = prevLayers.filter(
          (prevLayer) => prevLayer.id !== newLayer.id
        );
        updatedLayers.push(newLayer);
        return updatedLayers;
      });

      setWedges(geoJsonData);

      // Toggle the visibility of the wedge layer to true
      setLayerVisibility((prevVisibility) => {
  return {
    ...prevVisibility,
    'wedge-layer': true,
   
  };
});


    } catch (error) {
    console.error('Error populating wedges:', error);
  } finally {
    setIsWedgesLoading(false); // Stop loading regardless of success or failure
  }
};

  const retrySubmitWedges = async (retries = 3) => {
    for (let i = 0; i < retries; i++) {
      try {
        await submitWedges();
        return;
      } catch (error) {
        console.error(`Attempt ${i + 1} failed. Retrying...`);
      }
    }
    console.error('Max retries reached. Could not populate wedges.');
  };

  retrySubmitWedges();

  setWedges(null);
  setWedgeModalOpen(false);
}

const createWedgeLayer = (data, id, isVisible) => {
  return new GeoJsonLayer({
    id,
    data,
    filled: true,
    stroked: true,
    pickable: true,
    lineWidthUnits: 'pixels',
    lineWidthScale: 1,
    getFillColor: [0, 255, 0, 100],
    getLineColor: [0, 0, 0, 255],
    getLineWidth: 1,
    onHover: handleHover,
    visible: isVisible,
    onClick: (info, event) => { 
    if (event.rightButton) {
              console.log(info)
               setClickedWedge(info.object)
               setSelectedTower(info.object.properties.Tower)
            }
          }
        });
}

const clearWedges = () => {
  setWedges([]); // Clear the wedges array
  setSavedWedges(new Map()); // Clear the saved wedges map
  setRowData([]);


  // Toggle the switch off
  setLayerVisibility(prevVisibility => ({
    ...prevVisibility,
    'wedge-layer': false,
  }));
};


const savePlan = async (planData) => {
    try {
      await API.post('tapestryGeo', '/accesstool/manageplans/saveplan', {
        body: planData
      });

    } catch (error) {
      console.error('Error saving plan:', error);
    }
  };
  
  const addLayerWedges = (geoJsonData) => {
      geoJsonData.features.map((feature) => {
        
        const thisWedge = { azimuth: feature.properties.Azimuth, beamWidth: feature.properties.Beamwidth, 
                            sectorName: feature.properties["Sector Name"], tech: feature.properties.Tech, 
                            priority: feature.properties.Priority, radiusOverride: feature.properties["Radius Override"],
                            tower: feature.properties.Tower}
        
        savedWedges.set(feature.properties.coveredBy,thisWedge)
        
      })
};



const handleWedgeDoubleClick = async (wedge) => {
  if (!wedge) return;
    console.log("Double-click detected on wedge:", wedge);
  try {
    const bridger = wedge.properties.bridger; 
    console.log("Sending API request with bridger:", bridger, "and planID:", planId);
    const response = await API.post('tapestryGeo', '/accesstool/focusap', {
      body: {
        bridger: bridger,
        planID: planId,
        avoidBridgers: [] 
      }
    });
    console.log("API response:", response);
    const geoJsonData = response; // assuming this is your focused AP data
    setFocusedApData(geoJsonData); // Update the focused AP data state

    // Set the layer visibility here after the data is fetched
    if (geoJsonData && Object.keys(geoJsonData).length > 0) { // Assuming geoJsonData is an object with data
      setLayerVisibility(prev => ({ ...prev,
        'focused-ap-layer': true, // Ensure the layer is visible
        'focused-towers-layer': true, })); // Ensure the layer is visible
    }
  } catch (error) {
    console.error('Error fetching data for focused AP:', error);
  }
};

const toggleFocusedLayersVisibility = () => {
  
  toggleLayerVisibility('focused-towers-layer')
  toggleLayerVisibility('focused-ap-layer')
  // setLayerVisibility(prevVisibility => ({
  //   ...prevVisibility,
  //   'focused-towers-layer': !prevVisibility['focused-towers-layer'],
  //   'focused-ap-layer': !prevVisibility['focused-ap-layer'],
  // }));
};

// const displayGeoJsonData = (geoJsonData) => {
//   if (!geoJsonData) return;

//   const newLayer = new GeoJsonLayer({
//     id: 'focused-ap-layer',
//     data: geoJsonData,
//     pickable: true,
//     stroked: true,
//     filled: true,
//     lineWidthUnits: 'pixels',
//     lineWidthScale: 10, 
//     getLineColor: [153, 102, 255, 255], 
//     getLineWidth: d => d.geometry.type === 'LineString' ? 0.5 : 1, 
//     getFillColor: [255, 0, 0, 255], 
//     getRadius: d => d.geometry.type === 'Point' ? 0.5 : 1, 
//     pointRadiusUnits: 'pixels',
//     pointRadiusScale: 10, 
//     onHover: handleHover,
// });

//   setLayers((prevLayers) => {
//     const updatedLayers = prevLayers.filter(
//       (prevLayer) => prevLayer.id !== newLayer.id
//     );
//     updatedLayers.push(newLayer);
//     return updatedLayers;
//   });
// };


  const state = {
    infoTable, setInfoTable, editableInfoTable, setEditableInfoTable,
    searchText, setSearchText, listInfoTable, setlistInfoTable, apiData, 
    pointLayers, setPointLayers, lineLayers, setLineLayers, polygonLayers, 
    setPolygonLayers, tooltipInfo, setTooltipInfo,
    createPointLayer, createLineLayer, createPolygonLayer,hierarchyData, setHierarchyData, drawPolygon, mode, setMode, toggleEditMode,isSelected, infoTable, updateInfoTable,isLoading, setIsLoading,networkData,
   layers, setLayers,
    layerVisibility, setLayerVisibility,
    selectedFeatures, setSelectedFeatures,
    wedgeTooltipInfo, setWedgetooltipInfo,
    fetchDataAndUpdateLayers,
    toggleLayerVisibility,
    handleHover,tooltipContent, setTooltipContent, tooltipPosition, setTooltipPosition, wedgeModalOpen, setWedgeModalOpen, polygonData, setPolygonData,
    areasData, setAreasData, handleHover, handleTowerClick, handleWedgeSubmit,rowData,
  setRowData,submitWedgeHoverInfo,selectedRow, setSelectedRow,updateSelectedRow, handleWedgeSubmit,setSelectedAttribute,setHeatmapScaleFactor,heatmapScaleFactor,selectedTower, setSelectedTower,wedges, setWedges,handleSaveWedges,populateWedges,selectedWedge, setSelectedWedge,contextMenu, setMode, ModifyMode,handleEditClick,handleDeleteWedge, clickedWedge,clearWedges,
  setWedgeModalOpen,wedgeModalOpen,drawPolygonMode, setDrawPolygonMode, 
   handleOptionChange, toggleAccessMode,accessMode, setAccessMode, 
    isLassoModeEnabled, setLayers,  savedWedges, setRowData, setClickedWedge, setSelectedTower, savePlan, planId, setPlanId, addLayerWedges,lineLayerVisible, setLineLayerVisible,
    lineData, setLineData,isWedgesLoading,
  setIsWedgesLoading, toggleFocusedLayersVisibility,
 
  };
  
  // useEffect(() => {
  //   fetchDataAndUpdateLayers(layerVisibility);
  // }, [focusedTowersList, layerVisibility]); 
  
  useEffect(() => {
    console.log("Focused Towers Data:", focusedTowersData); 
    const newLayers = [...layers];
    
    console.log(focusedTowersData)
    // toggleLayerVisibility('focused-towers-layer')
    setLayers(newLayers);
  }, [focusedTowersData]);
  
  useEffect(() => {
  console.log("Layer visibility changed, updating layers:", layerVisibility);
  // Update layers based on the new visibility
  fetchDataAndUpdateLayers(layerVisibility, heatmapScaleFactor);
}, [heatmapScaleFactor, layerVisibility]);
// useEffect(() => {
//   console.log("drawn polygons",drawnPolygons)
//   stopDrawingPolygon(drawnPolygons);
// }, []);

  useEffect(() => {
  fetchPolygonAndTowerData();
}, []);

  
  useEffect(() => {
  const newPolygonMap = new Map();
  for (const [layerId, polygons] of polygonLayers.entries()) {
    newPolygonMap.set(layerId, createPolygonLayer(polygons, layerId));
  }
  setPolygonLayers(newPolygonMap);
}, [mode, selectedFeatureIndexes, isDrawing]);

useEffect(() => {
  console.log(mode);
  console.log(selectedFeatureIndexes);
}, [mode, selectedFeatureIndexes]);


  useEffect(() => {
    if (searchText === "") {
      setEditableInfoTable(infoTable);
    } else {
      const lowerCaseSearchText = searchText.toLowerCase();
      const filtered = infoTable.filter((row) =>
        Object.values(row).some((value) =>
          String(value).toLowerCase().includes(lowerCaseSearchText)
        )
      );
      setEditableInfoTable(filtered);
    }
  }, [searchText, infoTable]);

 useEffect(() => {
  const fetchData = async () => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    try {
      setIsLoading(true)
      const hierarchyDataPromise = fetchHierarchyData(signal);
      const apiDataPromise = amplifyAPITest(signal);

      const [resolvedHierarchyData, resolvedApiData] = await Promise.all([hierarchyDataPromise, apiDataPromise]);



      
      setApiData(resolvedApiData)
      setHierarchyData(resolvedHierarchyData);
      setlistInfoTable(resolvedApiData.thisInfoTable);
      setInfoTable(resolvedApiData.results);
      setEditableInfoTable(resolvedApiData.thisInfoTable);

      const newPointMap = new Map();
      const newLineMap = new Map();
      const newPolygonMap = new Map();

      resolvedApiData.results.forEach(item => {
        const response = item?.response;
        const id = item?.id;
        const features = response?.features;
        const points = [];
        const lines = [];
        const polygons = [];
        features?.forEach((item) => {
          const featureType = String(item.geometry.type);
  
          if (featureType === "Point") {
            points.push(item);
          } else if (featureType === "LineString") {
            lines.push(item);
          } else if (featureType === "Polygon" || featureType === "MultiPolygon") {
            polygons.push(item);
          }
        });

        if (points.length !== 0) {
          const pointLayer = createPointLayer(points, id);
          newPointMap.set(id, pointLayer);
        }
        if (lines.length !== 0) {
          const lineLayer = createLineLayer(lines, id);
          newLineMap.set(id, lineLayer);
        }
        if (polygons.length !== 0) {
          const polygonLayer = createPolygonLayer(polygons, id);
          newPolygonMap.set(id, polygonLayer);
        }
      });

      setPointLayers(newPointMap)
      setLineLayers(newLineMap)
      setPolygonLayers(newPolygonMap)
      setIsLoading(false)
    } catch (err) {
      if (err.name !== 'AbortError') {
        console.error(err);
      }
    }
    return () => abortController.abort();
  };
  fetchData();
}, []);

// useEffect(() => {
//   toggleLayerVisibility("census-block-layer")
//   toggleLayerVisibility("census-block-layer")
// },[selectedAttribute,heatmapScaleFactor]);


  return (
    <AppContext.Provider value={state}>
    {isLoadingLayers && (
        <div style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          zIndex: 9999,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}>
          <div className="spinner"></div> {/* You can replace this with your spinner component */}
        </div>
      )}
      {children}
     <ContextMenu id="wedge-context-menu">
  <MenuItem onClick={(e) => handleEditClick(e.clientX, e.clientY)}>Edit Wedge</MenuItem>
  <MenuItem onClick={() => handleDeleteWedge()}>Delete Wedge</MenuItem>
  <MenuItem onClick={() => focusOnTower()}>Focus Tower</MenuItem> 
  {/*<MenuItem onClick={() => removeFocusedTower(`${rightClickedTower.network}_${rightClickedTower.site}`)}>Delete Focus Tower</MenuItem>*/}
</ContextMenu>
    </AppContext.Provider>
  );
};

export default ContextProvider;

