import React, { useState } from 'react';
import { Edit } from '@material-ui/icons'; // Import Edit icon from Material-UI
import './EditableCell.css';

const EditableCell = ({ value = '', rowIndex, column, onUpdate }) => {
  const [editing, setEditing] = useState(false);
  const [newValue, setNewValue] = useState(value || '');

  const handleEdit = () => {
    setEditing(true);
  };

  const handleChange = (e) => {
    setNewValue(e.target.value);
  };

  const handleBlur = async () => {
    setEditing(false);
    // Pass the new value to the parent component to save changes
    await onUpdate(rowIndex, column, newValue);
  };

  return editing ? (
    <input
      type="text"
      value={newValue}
      onChange={handleChange}
      onBlur={handleBlur}
      autoFocus
    />
  ) : (
    <div className="EditableCell" onDoubleClick={handleEdit}>

      {value || ' '}
      <Edit style={{ visibility: 'hidden' }} /> {/* Edit icon is hidden by default */}
    </div>
  );
};

export default EditableCell;
