import { API } from 'aws-amplify';
import awsconfig from "../aws-exports"
import pako from 'pako';
import atob from 'atob';

const MAX_RETRIES = 10;  // Adjust to your needs

const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const retry = async (fn, maxRetries) => {
  for(let i = 0; i < maxRetries; i++){
    try {
      return await fn();
    } catch(err){
      console.error(err);
      const delayMs = Math.pow(2, i) * 1000;
      console.log(`Retrying in ${delayMs}ms...`);
      await delay(delayMs);
    }
  }
  throw new Error('Max retries exceeded');
};

export const amplifyAPITest = async () => {

  try {
    const thisInfoTable = await API.get("tapestryGeo", "/geoInfo");

    const tasks = thisInfoTable.map((element) => 
      retry(async () => {
        try {
          const bytes = await API.get("tapestryGeo", "/geoData", {
            queryStringParameters: {
              id: element.id.toString(),
              source_table: element.source_table,
              compress: "True",
            },
          });

          const compressedData = Uint8Array.from(atob(bytes), (c) => c.charCodeAt(0));
          const decompressedData = pako.inflate(compressedData, { to: "string" });
          const jsonObject = JSON.parse(decompressedData);
          // console.log(`Parsed JSON object for id: ${element.id}`, jsonObject);
          return {
            id: element.id.toString().concat(" ", element.source_table),
            response: jsonObject,
          };
        } catch (err) {
          console.error(`Error occurred with id: ${element.id}`);
          console.error(err);
          throw err; // rethrow error so that retry function can catch it
        }
      }, MAX_RETRIES)
    );
    console.log ('Tasks before promise.all', tasks )

    const results = await Promise.all(tasks);
  
    return { thisInfoTable, results};
  } catch (err) {
    console.error(err);
  }
};

export const fetchHierarchyData = async () => {
  try {
    const thisInfoTable = await API.get("tapestryGeo", "/geoInfo", {
      queryStringParameters: { hierarchy: "True" },
    });

    return thisInfoTable;
  } catch (err) {
    if (err.name !== "AbortError") {
      console.error(err);
    }
  }
};

export async function fetchUpdatedData() {

  try {
    let thisInfoTable = await API.get("tapestryGeo", "/geoInfo");
    console.log('Received updated data:', thisInfoTable);
    thisInfoTable = thisInfoTable.filter((item) => {
      return item.source_table === 'fiber_por_info' || item.source_table === 'rond_predictions'
    });

  } catch (err) {
    console.error(err);
    throw err; // Rethrow the error to be caught in the calling code
  }
}

export const fetchLatestData = async () => {
  try {
    let thisInfoTable = await API.get("tapestryGeo", "/geoInfo");
    thisInfoTable = thisInfoTable.filter(
      (item) =>
        item.source_table === "fiber_por_info" ||
        item.source_table === "rond_predictions"
    );

    const hierarchyData = await fetchHierarchyData();
    return { thisInfoTable, hierarchyData };

  } catch (err) {
    console.error(err);
    throw err; // Rethrow the error to be caught in the calling code
  }
};

