import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import TapestryTool from './Tapestry Tool/TapestryTool'; // THIS IS THE APP SWITCH
import AccessLayerTool from './Access Layer/AccessLayerTool';
import LandingPage from './Landing Page/LandingPage';
import { Amplify } from 'aws-amplify';
import awsconfig from './aws-exports';
import './index.css';
import UploadDocument from './Tapestry Tool/UploadDocument';
import AdminPage from './Access Layer/AdminPage';
import ContextProvider from './Umbrella/ContextProvider';
import TapestrySummary from './Tapestry Summary/TapestrySummary';
import DetailDesignTool from './Detail Design Tool/DetailDesignTool';
import mapboxgl from 'mapbox-gl'




Amplify.configure(awsconfig);

mapboxgl.accessToken = 'YOUR_MAPBOX_ACCESS_TOKEN';

ReactDOM.render(
  <ContextProvider>
  <React.StrictMode>
    <Router>
      <Switch>
        <Route path="/" exact component={LandingPage} />
        <Route path="/tapestry-tool" component={TapestryTool} />
        <Route path="/access-layer-tool" component={AccessLayerTool} />
        <Route path="/upload" component={UploadDocument} />
        <Route path="/admin" component={AdminPage} />
        <Route path="/tapestry-summary" component={TapestrySummary} />
        <Route path="/detail-design-tool" component={DetailDesignTool} />
      </Switch>
    </Router>
  </React.StrictMode>
  </ContextProvider>,
  document.getElementById('root')
);

