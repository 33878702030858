import React, { useState, useEffect } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import './TapestrySummary.css';
import { API } from 'aws-amplify';
import logo from '../Images/rise-broadband-logo.png'
import { PieChart, Pie, Tooltip, Cell } from 'recharts';


const TapestrySummary = () => {
  const [marketData, setMarketData] = useState([]);
  const [selectedMarketArea, setSelectedMarketArea] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const apiName = 'tapestryGeo'; // replace with your actual API name
        const path = '/summaryData/marketData';
        const response = await API.get(apiName, path);

        console.log(response);

        const transformedData = Object.entries(response).map(([marketArea, value]) => ({
          marketArea,
          ...value['Fiber Summary']
        }));
        
        const table2 = Object.entries(response).map(([marketArea, value]) => ({
          marketArea,
          ...value['Mock Table 1']
        }));
        
        const table3 = Object.entries(response).map(([marketArea, value]) => ({
          marketArea,
          ...value['Mock Table 2']
        }));
        
        const tableData = {Fiber:transformedData,
                            Mock1:table2,
                            Mock2:table3}

        setMarketData(tableData);
        if(transformedData.length > 0) {
          setSelectedMarketArea(transformedData[0].marketArea); // Set to first market area by default
        }
      } catch (e) {
        setError(e);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleSelectChange = (event) => {
    setSelectedMarketArea(event.target.value);
  }

const createTable = (data, tableName) => {
  if (!selectedMarketArea) return null;
  const selectedData = data.find(d => d.marketArea === selectedMarketArea);
  // Filter out 'marketArea' when creating columns
  const columns = Object.keys(selectedData)
    .filter(key => key !== 'marketArea')
    .map((key) => ({
      dataField: key,
      text: key,
    }));

  return (
    <div key={tableName} className="table-container">
      <h2>{tableName}</h2>
      <BootstrapTable 
        bootstrap4 
        keyField='marketArea' 
        data={[selectedData]} 
        columns={columns} 
        classes="table-style"
        headerClasses="table-style"
      />
    </div>
  );
};
const COLORS = ['#FF6384', '#36A2EB', '#FFCE56']; // Add more colors if needed

 const createPieChart = (data) => {
  console.log('Data:', data); // Add this line

  const pieData = data.map(({ marketArea, HHP }) => ({
    name: marketArea,
    value: HHP, 
  }));

  console.log('PieData:', pieData); // Add this line

  return (
    <div className="chart-container">
      <h2>HHP Summary</h2>
      <div className="pie-chart">
        <PieChart width={400} height={400}>
          <Pie
            data={pieData}
            cx="50%" // Change to percentage
            cy="50%" // Change to percentage
            labelLine={false}
            outerRadius={80} // Increase outerRadius
            fill="#8884d8"
            dataKey="value"
            label={({ name, percent }) => `${name} ${(percent * 100).toFixed(0)}%`}
          >
            {
              pieData.map((entry, index) => <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />)
            }
          </Pie>
          <Tooltip />
        </PieChart>
      </div>
    </div>
  );
};



  if (isLoading) return 'Loading...';
  if (error) return `Error: ${error.message}`;

return (
  <div className="content-container">
    <img src={logo} alt="Logo" className="logo" />
    <h1 className="page-title">Tapestry Summary</h1>
    {createPieChart(marketData.Fiber)}
    <div className="select-group">
      <label>Market Area:</label>
      <select value={selectedMarketArea} onChange={handleSelectChange}>
        {marketData.Fiber.map((data) => (
          <option key={data.marketArea} value={data.marketArea}>{data.marketArea}</option>
        ))}
      </select>
    </div>
    <div className="content-row"> 
      <div className="tables-container"> 
        {createTable(marketData.Fiber, 'Market Area Overview')}
       {/* {createTable(marketData.Mock1, 'Mock Data 1')}
        {createTable(marketData.Mock2, 'Mock Data 2')}*/}
      </div>
    </div>
  </div>
);



}

export default TapestrySummary;
