

export const fetch3GisLayer = async (baseURL, fields) => {
  try {
    // console.log("URL", baseURL);
    const fieldsFormatted = fields.join();

    let queryBase = {
      where: "OBJECTID IS NOT NULL",
      units: "esriSRUnit_Foot",
      outFields: fieldsFormatted,
      returnGeometry: true,
      outSR: 4326,
      resultOffset: 0,
      f: 'geojson'
    };

    const geoJson = { 'type': 'FeatureCollection', 'features': [] };

    while (true) {
      const queryString = new URLSearchParams(queryBase).toString();
      const response = await fetch(`${baseURL}?${queryString}`, { method: 'get' });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const results = await response.json();
      // console.log(results);

      if (results.exceededTransferLimit) {
        queryBase.resultOffset += results.features.length;
        geoJson.features.push(...results.features);
      } else {
        geoJson.features.push(...results.features);
        break;
      }
    }

    return geoJson;

  } catch (err) {
    console.error(err.message);
  }
};
