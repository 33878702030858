import React from 'react';
import Expand from '../Icons/Expand';

const ExpandButton = ({ onClick }) => {
  return (
    <div
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 101,
        padding: '2px',
        borderRadius: '12px',
        backgroundColor: 'white',
      }}
      onClick={onClick}
    >
      <Expand />
    </div>
  );
};

export default ExpandButton;
