import React, { useState } from 'react';
import mbxGeocoding from '@mapbox/mapbox-sdk/services/geocoding';
import mapboxClient from '@mapbox/mapbox-sdk';
import styles from './CustomGeocoderTaptool.css';


const mapboxAccessToken = "pk.eyJ1IjoiZXN0b2VsemVsIiwiYSI6ImNsaHRnNmgyeTBjajgza3BlcXVmanNkb3AifQ.X47Gk4-fZcII4-3WLR-adw";
const geocodingClient = mbxGeocoding(mapboxClient({ accessToken: mapboxAccessToken }));

const CustomGeocoderTaptool = ({ onSearch, placeholder }) => {
  const [searchInput, setSearchInput] = useState('');

  const handleSearchInputChange = (event) => {
    setSearchInput(event.target.value);
  };

 const handleSearch = async () => {
  if (onSearch && typeof searchInput === 'string' && searchInput.trim() !== '') {
    const response = await geocodingClient
      .forwardGeocode({ query: searchInput.trim(), limit: 1 })
      .send();

    if (response && response.body && response.body.features && response.body.features.length > 0) {
      const selectedLocation = response.body.features[0];
      const [longitude, latitude] = selectedLocation.geometry.coordinates;
      console.log('Selected location:', selectedLocation);
      console.log('Latitude:', latitude);
      console.log('Longitude:', longitude);
      onSearch(selectedLocation);
    }
  }
};



    return (
    <div className={styles['custom-geocoder']}>
        <input
        type="text"
        value={searchInput}
        onChange={handleSearchInputChange}
        placeholder={placeholder}
        className={styles['custom-geocoder__input']}
      />
      <button
       onClick={handleSearch}
        className={styles['custom-geocoder__button']}
      >
        Search
      </button>
    </div>
  );
};

export default CustomGeocoderTaptool;