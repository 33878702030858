import React, { useState, useEffect } from 'react'; 
import BootstrapTable from 'react-bootstrap-table-next'; 
import { API } from 'aws-amplify';
import { Link } from 'react-router-dom';
import './LandingPage.css';
import logo from '../Images/rise-broadband-logo.png';
import { withAuthenticator } from '@aws-amplify/ui-react';
import { Auth } from 'aws-amplify';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';
import { ModuleRegistry } from "@ag-grid-community/core";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { GridChartsModule } from "@ag-grid-enterprise/charts";
import largeDataSet from './largeDataSet.json';


ModuleRegistry.registerModules([ClientSideRowModelModule, GridChartsModule]);



const LandingPage = () => {
  const [marketData, setMarketData] = useState([]);
  const [selectedMarketArea, setSelectedMarketArea] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
    const [gridRowData] = React.useState(largeDataSet);
    const [removedColumns, setRemovedColumns] = useState([]);

  
   useEffect(() => {
    const fetchData = async () => {
      try {
        const apiName = 'tapestryGeo'; // replace with your actual API name
        const path = '/summaryData/marketData';
        const response = await API.get(apiName, path);

        console.log(response);

        const transformedData = Object.entries(response).map(([marketArea, value]) => ({
          marketArea,
          ...value['Fiber Summary']
        }));
        
        const table2 = Object.entries(response).map(([marketArea, value]) => ({
          marketArea,
          ...value['Mock Table 1']
        }));
        
        const table3 = Object.entries(response).map(([marketArea, value]) => ({
          marketArea,
          ...value['Mock Table 2']
        }));
        
        const tableData = {Fiber:transformedData,
                            Mock1:table2,
                            Mock2:table3}

        setMarketData(tableData);
        if(transformedData.length > 0) {
          setSelectedMarketArea(transformedData[0].marketArea); // Set to first market area by default
        }
      } catch (e) {
        setError(e);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);
    const handleSelectChange = (event) => {
    setSelectedMarketArea(event.target.value);
  }

const createTable = (data, tableName) => {
  if (!selectedMarketArea) return null;
  const selectedData = data.find(d => d.marketArea === selectedMarketArea);
  // Filter out 'marketArea' when creating columns
  const columns = Object.keys(selectedData)
    .filter(key => key !== 'marketArea')
    .map((key) => ({
      dataField: key,
      text: key,
    }));

  return (
    <div key={tableName} className="table-container">
      <h2>{tableName}</h2>
      <BootstrapTable 
        bootstrap4 
        keyField='marketArea' 
        data={[selectedData]} 
        columns={columns} 
        classes="table-style"
        headerClasses="table-style"
      />
    </div>
  );
};
  const handleSignOut = async () => {
    try {
      await Auth.signOut();
    } catch (error) {
      console.error('error signing out: ', error);
    }
  }
  
  const getDataPath = (data) => {
  if (data && data.employeeId) {
    return data.employeeId.split('.');
  }
  return [];
};

// const rowModelType = 'clientSide';
//   const treeData = true;
//   const autoGroupColumnDef = {
//     headerName: 'Make',
//     field: 'make',
//     cellRenderer: 'agGroupCellRenderer',
//     cellRendererParams: {
//       checkbox: true
//     }
//   };
  
  const SimpleCellRenderer = (params) => {
  if (params.node.group) {
    return params.node.expanded ? '-' : '>';
  }
  return params.value;
};

 
 const groupRowAggNodes = (nodes) => {
  const sums = { hhp: 0, path: 0 };
  nodes.forEach((node) => {
    sums.hhp += node.group ? node.aggData.hhp : node.data.hhp;
    sums.path += node.group ? node.aggData.path : node.data.path;
  });
  return sums;
};

const columnDefs = [
  { 
    field: 'areaName', 
    rowGroup: true, 
    hide: true,
    cellRenderer: 'SimpleCellRenderer' // Use the SimpleCellRenderer function
  },
  { 
    field: 'hhp', 
    aggFunc: params => Array.isArray(params.values) ? params.values.reduce((acc, val) => acc + val, 0) : 0,
    headerName: 'HHP',
    cellRenderer: 'SimpleCellRenderer' // Use the SimpleCellRenderer function
  },
  { 
    field: 'path', 
    aggFunc: params => Array.isArray(params.values) ? params.values.reduce((acc, val) => acc + val, 0) : 0,
    headerName: 'Path',
    cellRenderer: 'SimpleCellRenderer' // Use the SimpleCellRenderer function
  }
];

const defaultColDef = {
  flex: 1,
  minWidth: 100,
  resizable: true,
  sortable: true,
  filter: true
};


   const autoGroupColumnDef = {
    headerName: 'Area Name',
    cellRenderer: 'agGroupCellRenderer',
    cellRendererParams: {
      checkbox: true
    }
  };

  const onGridReady = (params) => {
  setGridApi(params.api);
  setGridColumnApi(params.columnApi);
  // Comment out or remove the line below to keep rows initially collapsed
  // params.api.expandAll();
  if (params.columnApi) {
    sizeColumnsToFit();
  }
};
  const sizeColumnsToFit = () => {
  if (!gridColumnApi) {
    console.warn("Grid Column API has not been initialized yet.");
    return;
  }
  const allColumnIds = [];
  gridColumnApi.getAllColumns().forEach((column) => {
    allColumnIds.push(column.colId);
  });
  gridColumnApi.autoSizeColumns(allColumnIds);
};
const onCellContextMenu = params => {
  // Remove any existing context menu
  const existingMenu = document.getElementById('context-menu');
  if (existingMenu) {
    existingMenu.remove();
  }

  // Check if gridApi is null
  if (gridApi) {
    const selectedNodes = gridApi.getSelectedNodes();
    // Check if selectedNodes is not null
    if (selectedNodes && selectedNodes.length > 0) {
      const contextMenu = document.createElement('div');
      contextMenu.id = 'context-menu';
      const barChartBtn = document.createElement('button');
      barChartBtn.innerHTML = 'Create Bar Chart';
      barChartBtn.onclick = () => createChart('groupedColumn');
      contextMenu.appendChild(barChartBtn);

      const pieChartBtn = document.createElement('button');
      pieChartBtn.innerHTML = 'Create Pie Chart';
      pieChartBtn.onclick = () => createChart('pie');
      contextMenu.appendChild(pieChartBtn);

      document.body.appendChild(contextMenu);
      contextMenu.style.position = 'absolute';
      contextMenu.style.left = `${params.event.clientX}px`;
      contextMenu.style.top = `${params.event.clientY}px`;
    }
  }
};

  const selectAllRowsInGroup = () => {
  if (!gridApi) return;
  const selectedNodes = gridApi.getSelectedNodes();
  if (selectedNodes.length > 0) {
    const parentNode = selectedNodes[0].parent;
    if (parentNode) { // Check if parentNode is not undefined
      parentNode.setSelected(true, true); // Select all children
    } else {
      console.warn("Parent node is undefined. This is likely a root node.");
    }
  }
};

const removeColumn = (colId) => {
    if (gridColumnApi) {
      gridColumnApi.setColumnVisible(colId, false);
      setRemovedColumns([...removedColumns, colId]);
    }
  };

  // New function to restore a column
  const restoreColumn = (colId) => {
    if (gridColumnApi) {
      gridColumnApi.setColumnVisible(colId, true);
      setRemovedColumns(removedColumns.filter(id => id !== colId));
    }
  };




  const getContextMenuItems = (params) => {
    const defaultItems = params.defaultItems;
    const customItems = [
      {
        name: 'Create Bar Chart',
        action: () => createChart('groupedColumn')
      },
      {
        name: 'Create Pie Chart',
        action: () => createChart('pie')
      },
      {
        name: 'Select All', // New menu item
        action: () => selectAllRowsInGroup()
      }
    ];
    return [...customItems, ...defaultItems];
  };

  const createChart = (chartType) => {
    if (!gridApi) {
      console.error("gridApi is not initialized");
      return;
    }
    const selectedNodes = gridApi.getSelectedNodes();
    if (!selectedNodes || selectedNodes.length === 0) {
      console.warn("No nodes selected");
      return;
    }
    const selectedData = selectedNodes.map(node => node.data);
    const chartRange = {
      rowStartIndex: selectedNodes[0].rowIndex,
      rowEndIndex: selectedNodes[selectedNodes.length - 1].rowIndex,
      columns: ['hhp', 'path']  // Adjust these columns based on your data
    };
    gridApi.createRangeChart({
      cellRange: chartRange,
      chartType,
      chartContainer: document.getElementById('chart-container')
    });
  };

   return (
  <div className="landing-page">
    <div className="header">
      <img src={logo} alt="Logo" className="logo" />
      <button onClick={handleSignOut} className="sign-out">Sign Out</button>
    </div>
    <div className="h1-container">
    <h1>Welcome to the Tools Portal</h1>
    </div>
    <div className="content">
    
    <div className="footer">
        <Link to="/tapestry-tool" className="tool-link">Tapestry Tool</Link>
        <Link to="/tapestry-summary" className="tool-link">Tapestry Summary</Link>
        <Link to="/access-layer-tool" className="tool-link">Access Layer</Link>
        <Link to="/detail-design-tool" className="tool-link">Detailed Design</Link>
        <a href="https://drive.google.com/drive/folders/1uMDI1Esd0UoFBrtBPyUPFjLWMlcB4YD5?usp=drive_link" className="tool-link">Transport Tool</a>
      </div>
      {/*<div className="select-group">
        <label>Market Area:</label>
        <select value={selectedMarketArea} onChange={handleSelectChange}>
          {marketData.Fiber && marketData.Fiber.map((data) => (
            <option key={data.marketArea} value={data.marketArea}>{data.marketArea}</option>
          ))}
        </select>
      </div>
      {createTable(marketData.Fiber, 'Market Area Overview')}
      <div id="chart-container"></div>
      <div className="ag-theme-alpine">
            <div id="toolbar" 
              onDrop={e => {
                e.preventDefault();
                const colId = e.dataTransfer.getData("colId");
                restoreColumn(colId);
              }} 
              onDragOver={e => e.preventDefault()}>
              {removedColumns.length > 0 ? (
                removedColumns.map(colId => (
                  <div key={colId} 
                      draggable 
                      onDragStart={e => e.dataTransfer.setData("colId", colId)}>
                    {colId}
                  </div>
                ))
              ) : (
                <div className="placeholder">Drag here to set row groups</div>
              )}
            </div>
            <AgGridReact
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            autoGroupColumnDef={autoGroupColumnDef}
            rowData={largeDataSet}
            rowDragManaged={true}
            animateRows={true}
            onGridReady={onGridReady}
            groupDefaultExpanded={0} // Rows initially collapsed
            rowSelection="multiple"
            getContextMenuItems={getContextMenuItems}
            groupRowAggNodes={groupRowAggNodes}
            frameworkComponents={{
              SimpleCellRenderer // Register the SimpleCellRenderer component
            }}
            onColumnVisible={params => {
              if (!params.visible) {
                removeColumn(params.column.colId);
              }
            }}
           />
         </div>*/}
    </div>
    
    </div>
  );
};
export default withAuthenticator(LandingPage, { includeGreetings: true });


