import React, { useState, useEffect } from 'react';
import { API, Auth } from 'aws-amplify';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Collapse from '@material-ui/core/Collapse';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { GeoJsonLayer } from '@deck.gl/layers';
import AppContext from '../Umbrella/Context';
import { WebMercatorViewport } from '@deck.gl/core';
import './SessionList.css';


const SessionList = ({ saveCurrentSession, onShowSession, sessionListData, setViewport}) => {
  const [sessionData, setSessionData] = useState({});
  const [loading, setLoading] = useState(false); // State to manage loading status
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [expandedUser, setExpandedUser] = useState(null);
  const [expandedCategory, setExpandedCategory] = useState(null);
  const [expandedId, setExpandedId] = useState(null);
  const [displayedSessionIds, setDisplayedSessionIds] = useState(new Set());
  
  
  
  const { handleHover, savedWedges, setRowData, setClickedWedge, setSelectedTower, setLayers, addLayerWedges} = React.useContext(AppContext);
  

  const fetchSessions = async () => {
    setLoading(true); // Start loading
    try {
      const user = await Auth.currentAuthenticatedUser();
      const username = user.attributes.email;
      const response = await API.get('tapestryGeo', '/accesstool/manageplans/listplans', {
        queryStringParameters: { "username": username, "heirarchy": "Yes" },
        timeout: 60000,
      });
      setSessionData(response);
    } catch (error) {
      console.error('Error fetching sessions with hierarchy', error);
    }
    setLoading(false); // Stop loading
  };

  // Fetch sessions when the component mounts
  useEffect(() => {
    fetchSessions();
  }, []);
  
  
  useEffect(() => {
  setSessionData(sessionListData);
}, [sessionListData]);




  const toggleDrawer = (open) => async (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    // If we are opening the drawer, fetch the sessions
    if (open) {
      await fetchSessions();
    }

    setDrawerOpen(open);
  };
  
  // const handleListItemClick = (sessionId) => {
  //   // Toggle between null and the selected sessionId
  //   setExpandedSessionId(prevSessionId => prevSessionId === sessionId ? null : sessionId);
  // };
  
  const handleUserClick = (username) => {
    console.log(`User clicked: ${username}`);
    setExpandedUser(expandedUser === username ? null : username);
    setExpandedCategory(null);
    setExpandedId(null);
  };

  const handleCategoryClick = (categoryName) => {
    console.log(`Category clicked: ${categoryName}`);
    setExpandedCategory(expandedCategory === categoryName ? null : categoryName);
    setExpandedId(null);
  };

  const handleIdClick = (id) => {
    console.log(`ID clicked: ${id}`);
    setExpandedId(expandedId === id ? null : id);
  };
  

const handleShowSessionClick = async (sessionId) => {
        const newDisplayedSessionIds = new Set(displayedSessionIds);
        if (newDisplayedSessionIds.has(sessionId)) {
            // Hide the session
            newDisplayedSessionIds.delete(sessionId);
            setDisplayedSessionIds(newDisplayedSessionIds);
            setLayers(prevLayers => prevLayers.filter(layer => layer.id !== `session-layer-${sessionId}`));
        } else {
    // Show the session
    try {
      const response = await API.get('tapestryGeo', '/accesstool/manageplans/getplan', {
        queryStringParameters: { "planid": sessionId }
      });

      if (response.type === 'FeatureCollection') {
        const geoJsonData = response;

        // Calculate bounds
        const bounds = geoJsonData.features.reduce((bounds, feature) => {
          const [minLng, minLat, maxLng, maxLat] = feature.geometry.coordinates[0].reduce(
            (b, coord) => [Math.min(coord[0], b[0]), Math.min(coord[1], b[1]), Math.max(coord[0], b[2]), Math.max(coord[1], b[3])],
            [Infinity, Infinity, -Infinity, -Infinity]
          );
          return [Math.min(minLng, bounds[0]), Math.min(minLat, bounds[1]), Math.max(maxLng, bounds[2]), Math.max(maxLat, bounds[3])];
        }, [Infinity, Infinity, -Infinity, -Infinity]);

        // Fit the viewport
        const { longitude, latitude, zoom } = new WebMercatorViewport({
          width: window.innerWidth,
          height: window.innerHeight,
        }).fitBounds([[bounds[0], bounds[1]], [bounds[2], bounds[3]]], { padding: 20 });

        // Set the viewport
        setViewport && setViewport({ longitude, latitude, zoom });

        // Create the session layer
        const sessionLayer = new GeoJsonLayer({
          id: `session-layer-${sessionId}`,
          data: geoJsonData,
          filled: true,
          stroked: true,
          pickable: true,
          lineWidthUnits: 'pixels',
          lineWidthScale: 1,
          getFillColor: [135, 206, 250, 100],
          getLineColor: [0, 0, 0, 255],
          getLineWidth: 1,
          onHover: handleHover,
          onClick: (info, event) => {
            if (event.rightButton) {
              setClickedWedge(info.object);
              setSelectedTower(info.object.properties.Tower);
            }
          }
        });

          newDisplayedSessionIds.add(sessionId);
            setDisplayedSessionIds(newDisplayedSessionIds);
            setLayers(prevLayers => [...prevLayers, sessionLayer]);
      } else {
        console.error('Invalid geoJSON data:', response);
      }
    } catch (error) {
      console.error('Error fetching session data', error);
    }
  }
};

  
    const formatTimestamp = (timestamp) => {
    return new Date(timestamp).toLocaleString();
  };
  
  const handleAddSessionClick = async (sessionId) => {
  try {
    const response = await API.get('tapestryGeo', '/accesstool/manageplans/getplan', {
      queryStringParameters: { "planid": sessionId }
    });
    if (response.type === 'FeatureCollection') {
      const sessionLayer = new GeoJsonLayer({
        id: `session-layer-${sessionId}`,
        data: response,
        filled: true,
        stroked: true,
        pickable: true,
        lineWidthUnits: 'pixels',
        lineWidthScale: 1,
        getFillColor: [255, 165, 0, 100], // Orange color with opacity (RGBA)
        getLineColor: [0, 0, 0, 255],
        getLineWidth: 1,
        onHover: handleHover,
      
      });
      addLayerWedges(response);
      setLayers(prevLayers => [...prevLayers, sessionLayer]);
    
    }
  } catch (error) {
    console.error('Error fetching session data for adding wedges', error);
  }
};


  const unsavePlan = async (planId) => {
  try {
    // Call the API to unsave the plan
    const response = await API.post('tapestryGeo', '/accesstool/manageplans/unsaveplan', {
      body: { planid: planId }
    });

    // If the API call was successful, update the session list
    if (response.status === 200) {
      // Update the sessionListData to set the plan as unsaved without refetching
      const updatedSessionData = { ...sessionData };
      // Assuming the saved plans are under the 'Saved' key
      updatedSessionData['Saved'].filter(plan => plan.id !== planId);
      setSessionData(updatedSessionData);
    } else {
      console.error('Unsave plan response:', response);
    }
  } catch (error) {
    console.error('Error unsaving the plan:', error);
  }
};


  return (
   <div>
      <IconButton
        style={{
          position: 'absolute',
          top: '20px',
          right: '20px',
          zIndex: 1000,
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          borderRadius: '50%',
          padding: '8px',
          boxShadow: '0 2px 4px rgba(0,0,0,0.3)',
          color: 'white'
        }}
        onClick={toggleDrawer(true)}
      >
        <MenuIcon />
      </IconButton>
      <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>
        {loading ? (
          <div className="session-list-loader">
            <CircularProgress />
          </div>
        ) : (
          <List className="session-list">
  {Object.entries(sessionData).map(([username, categories]) => {
  return (
    <React.Fragment key={username}>
      <ListItem button onClick={() => handleUserClick(username)}>
        <ListItemText primary={username} />
      </ListItem>
      <Collapse in={expandedUser === username}>
        {Object.entries(categories).map(([categoryName, ids]) => {
          return (
            <React.Fragment key={categoryName}>
              <ListItem button onClick={() => handleCategoryClick(categoryName)}>
                <ListItemText primary={categoryName} inset />
              </ListItem>
              <Collapse in={expandedCategory === categoryName && expandedUser === username}>
                {ids.map((session) => {
                  return (
                    <React.Fragment key={session.id}>
                      <ListItem button onClick={() => handleIdClick(session.id)}>
                        <ListItemText
                          primary={session.planname || `ID: ${session.id}`}
                          secondary={
                            <>
                              {session.notes && <div>{session.notes}</div>}
                              <div>{formatTimestamp(session.timestamp)}</div>
                            </>
                          }
                        />
                      </ListItem>
                     <Collapse in={expandedId === session.id}>
  <div className="button-group">
    <Button className="show-session-button" onClick={() => handleShowSessionClick(session.id)}>
      {displayedSessionIds.has(session.id) ? 'Hide Session' : 'Show Session'}
    </Button>
    <Button className="add-session-button" onClick={() => handleAddSessionClick(session.id)}>
      Add Session
    </Button>
    {categoryName === "Saved" && (
      <Button className="unsave-plan-button" onClick={() => unsavePlan(session.id)}>
        Unsave Plan
      </Button>
    )}
  </div>
</Collapse>

                    </React.Fragment>
                  );
                })}
              </Collapse>
            </React.Fragment>
          );
        })}
      </Collapse>
    </React.Fragment>
  );
})}

      </List>
        )}
      </Drawer>
    </div>
  );
};

export default SessionList;

