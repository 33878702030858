import React, { useState, useEffect } from 'react';
import mbxGeocoding from '@mapbox/mapbox-sdk/services/geocoding';
import mapboxClient from '@mapbox/mapbox-sdk';
import styles from './CustomGeocoder.module.css';

const mapboxAccessToken = "pk.eyJ1IjoiZXN0b2VsemVsIiwiYSI6ImNsaHRnNmgyeTBjajgza3BlcXVmanNkb3AifQ.X47Gk4-fZcII4-3WLR-adw";
const geocodingClient = mbxGeocoding(mapboxClient({ accessToken: mapboxAccessToken }));

const CustomGeocoder = ({ onSearch, placeholder }) => {
  const [searchInput, setSearchInput] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [debounceTimeout, setDebounceTimeout] = useState(null);

  useEffect(() => {
    // Clear the debounce timeout when the component is unmounted
    return () => {
      if (debounceTimeout) clearTimeout(debounceTimeout);
    };
  }, [debounceTimeout]);

  const handleSearchInputChange = (event) => {
    const inputValue = event.target.value;
    setSearchInput(inputValue);

    if (debounceTimeout) clearTimeout(debounceTimeout);

    setDebounceTimeout(setTimeout(() => {
      if (inputValue.trim() === '' || validateLatLong(inputValue)) {
        setSuggestions([]);
        return;
      }

      geocodingClient.forwardGeocode({ query: inputValue, autocomplete: true, limit: 5 })
        .send()
        .then((response) => {
          setSuggestions(response.body.features);
        }).catch((error) => {
          console.error('Error fetching suggestions:', error);
        });
    }, 500));
  };

  const validateLatLong = (query) => {
    const regexPattern = /^(-?\d+(\.\d+)?),\s*(-?\d+(\.\d+)?)$/;
    return regexPattern.test(query);
  };

  const handleSearch = async () => {
    let query = searchInput.trim();
    const isLatLong = validateLatLong(query);

    if (isLatLong) {
      const [lat, lon] = query.split(',').map(coord => parseFloat(coord.trim()));
      if (!isNaN(lat) && !isNaN(lon)) {
        try {
          const response = await geocodingClient.reverseGeocode({ query: [lon, lat], limit: 1 }).send();
          if (response.body.features.length > 0) {
            onSearch(response.body.features[0]);
            setSuggestions([]);
          }
        } catch (error) {
          console.error('Error during reverse geocode:', error);
        }
      }
    } else {
      if (query !== '') {
        try {
          const response = await geocodingClient.forwardGeocode({ query, autocomplete: true, limit: 1 }).send();
          if (response.body.features.length > 0) {
            onSearch(response.body.features[0]);
            setSuggestions([]);
          }
        } catch (error) {
          console.error('Error during geocode:', error);
        }
      }
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  return (
    <div className={styles['custom-geocoder']}>
      <input
        type="text"
        value={searchInput}
        onChange={handleSearchInputChange}
        onKeyPress={handleKeyPress}
        placeholder={placeholder}
        className={styles['custom-geocoder__input']}
      />
      <button onClick={handleSearch} className={styles['custom-geocoder__button']}>
        Search
      </button>
      <ul className={styles['custom-geocoder__suggestions']}>
        {suggestions.map((suggestion) => (
          <li key={suggestion.id} onMouseDown={() => { setSearchInput(suggestion.place_name); handleSearch(); }}>
            {suggestion.place_name}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default CustomGeocoder;
