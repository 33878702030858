import React, { useState, useEffect } from 'react';
import './AccessLayer.css';
import AppContext from '../Umbrella/Context';

const WedgeInputModal = ({ isOpen, onClose, onSave, selectedTower, tower, initialData, isEditing,setWedgeModalOpen }) => {
  const [wedges, setWedges] = useState([{ azimuth: '', beamWidth: '90', sectorName: '', tech: 'Tarana', priority: 'Yes', radiusOverride: '' }]);
  const [showAdvanced, setShowAdvanced] = useState(false);


  const {handleSaveWedges} = React.useContext(AppContext);

  useEffect(() => {
    // Initialize beamWidth to 90 when the modal opens
    if (isOpen) {
      setWedges(wedges.map(w => ({ ...w, beamWidth: w.beamWidth || '90' })));
    }
  }, [isOpen]);
  
  const handleAddWedge = () => {
  const newWedge = { azimuth: '', beamWidth: '90', sectorName: '', tech: 'Tarana', priority: 'Yes', radiusOverride: '' };
  setWedges([...wedges, newWedge]);
};

  const handleChange = (index, field, value) => {
  const newWedges = [...wedges];
  if (field === 'tech') {
    newWedges[index]['priority'] = value === 'Tarana' ? 'Yes' : value === '450M' ? 'No' : newWedges[index]['priority'];
  }
  newWedges[index][field] = value;
  setWedges(newWedges);
};

const handleSave = () => {
  // console.log("Saving wedges:", wedges);  
  // onSave(wedges);  
  handleSaveWedges(wedges);  
  onClose();
};
  
    return (
   <div className={`modal ${isOpen ? 'is-open' : ''}`} onClick={(e) => e.stopPropagation()}>
      <div className="wedge-input-modal compact">
        <div className="modal-content" style={{ position: 'relative' }}>
          <button className="close-button" onClick={onClose}>X</button>
          {wedges.map((wedge, index) => (
            <div key={index} className={`wedge-input ${showAdvanced ? 'advanced' : ''}`}>
                <div className="input-group">
                  <label className="small-label">Sector Name</label>
                  <input type="text" value={wedge.sectorName} onChange={(e) => handleChange(index, 'sectorName', e.target.value)}  readOnly={isEditing} />
                </div>
                <div className="input-group">
                  <label className="small-label">Azimuth</label>
                  <input type="number" value={wedge.azimuth} onChange={(e) => handleChange(index, 'azimuth', e.target.value)} />
                </div>
                <div className="input-group">
                  <label className="small-label">Tech</label>
                  <select value={wedge.tech} onChange={(e) => handleChange(index, 'tech', e.target.value)}>
                    <option value="Tarana">Tarana</option>
                    <option value="450M">450M</option>
                  </select>
                
             </div>
              {showAdvanced && (
              <div className="advanced-settings">
                
                  <div className="input-group">
                    <label className="small-label">Beam Width</label>
                    <input className="small-input" type="number" value={wedge.beamWidth} onChange={(e) => handleChange(index, 'beamWidth', e.target.value)} />
                  </div>
                  <div className="input-group">
 <label className="small-label">Priority</label>
  <select className="priority-select" value={wedge.priority} onChange={(e) => handleChange(index, 'priority', e.target.value)}>
    <option value="Yes">Yes</option>
    <option value="No">No</option>
  </select>
</div>
                  <div className="input-group">
                    <label className="small-label">Radius Override</label>
                    <input className="small-input" type="number" value={wedge.radiusOverride} onChange={(e) => handleChange(index, 'radiusOverride', e.target.value)} />
                  </div>
                </div>
              )}
            </div>
          ))}
           <div className="button-group">
            <button onClick={handleAddWedge}>+ Add</button>
            <button onClick={handleSave}>Save</button> 
         </div>
          <a href="#" className="advanced-link" onClick={() => setShowAdvanced(!showAdvanced)}>
            {showAdvanced ? 'Hide Advanced' : 'Advanced'}
          </a>
        </div>
      </div>
    </div>
  );
};
export default WedgeInputModal;

