import React from 'react';
import { Link } from 'react-router-dom';

const ButtonLink = ({ pathname, state, buttonText }) => {
  return (
    <div style={{ position: 'absolute', top: 0, right: 0, zIndex: 1000 }}>
      <Link to={{ pathname: pathname, state: state }}>
        <button style={{ whiteSpace: 'nowrap' }}>{buttonText}</button>
      </Link>
    </div>
  );
};

export default ButtonLink;
