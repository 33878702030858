import React from 'react';

const LayerMenu = ({
  hierarchyData,
  expandedSections,
  toggleSection,
  toggleLayersInHierarchy,
  visibleIDs,
  toggleLayerVisibility,
}) => {
  return (
    <div
      id="layerMenu"
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 100,
        width: '340px',
        maxHeight: 'calc(100vh - 50px)',
        overflow: 'auto',
        background: '#ffffff',
        padding: '20px',
        borderRadius: '5px',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
      }}
    >
      <div>
       {hierarchyData && Object.keys(hierarchyData).map((key) => (

          <React.Fragment key={key}>
            <div className="layerchoice" style={{ width: "100%" }}>
              <span onClick={() => toggleSection(`${key}`)}>
               {expandedSections[`${key}`] ? "▼" : "▶"}
              </span>
              <input
                type="checkbox"
                style={{ float: "Left" }}
                onChange={() => toggleLayersInHierarchy(hierarchyData[key])}
              />
              <label style={{ marginLeft: "8px"}}>{ key }</label>
              
            </div>
            {expandedSections[`${key}`] && (
              <div style={{ paddingLeft: 20 }} className="scrollable-section">
                {Object.keys(hierarchyData[key]).map((category) => (
                  <React.Fragment key={`${category}-${key}`}>
                    <div className="layerchoice">
                      <span onClick={() => toggleSection(`${category}-${key}`)}>
                       {expandedSections[`${category}-${key}`] ? "▼" : "▶"}
                      </span>
                      <input
                        type="checkbox"
                        style={{ float: "right" }}
                        onChange={() => toggleLayersInHierarchy(hierarchyData[key][category])}
                      />
                      <label style={{ marginLeft: "8px"}}>{category}</label>
                      
                    </div>
                    {expandedSections[`${category}-${key}`] && (
                      <div style={{ paddingLeft: 20 }} className="scrollable-section">
                        {Object.keys(hierarchyData[key][category]).map((item) => (
                          <React.Fragment key={`${item}-${category}-${key}`}>
                            <div className="layerchoice">
                              <span onClick={() => toggleSection(`${item}-${category}-${key}`)}>
                                {expandedSections[`${item}-${category}-${key}`] ? "▼" : "▶"}
                              </span>
                              <input
                                type="checkbox"
                                style={{ float: "right" }}
                                onChange={() => toggleLayersInHierarchy(hierarchyData[key][category][item])}
                              />
                              <label style={{ marginLeft: "8px"}}>{item}</label>
                            </div>
                            {expandedSections[`${item}-${category}-${key}`] && (
                              <div style={{ paddingLeft: 20 }} className="scrollable-section">
                                {hierarchyData[key][category][item].map((cluster) => (
                                  <React.Fragment key={`${cluster.id}-${item}-${category}-${key}`}>
                                    <div className="layerchoice">
                                      <span onClick={() => toggleSection(`${cluster.name}-${item}-${category}-${key}`)}>
                                       {expandedSections[`${cluster.name}-${item}-${category}-${key}`] ? "▼" : "▶"}
                                      </span>
                                      <input
                                        type="checkbox"
                                        style={{ float: "right" }}
                                        onChange={() => toggleLayersInHierarchy(cluster)}
                                      />
                                      <label style={{ marginLeft: "8px"}}>{cluster.name}</label> 
                                      
                                    </div>
                                    {expandedSections[`${cluster.name}-${item}-${category}-${key}`] && (
                                      <div style={{ paddingLeft: 20 }} className="scrollable-section">
                                        {cluster.type.map((thisType) => (
                                          <div
                                            key={`${cluster.id} ${thisType}`}
                                            style={{ paddingLeft: 40 }}
                                            className="scrollable-section"
                                          >
                                            <div className="layerchoice">
                                              <input
                                                type="checkbox"
                                                style={{ float: "right" }}
                                                checked= {visibleIDs.has(`${cluster.id} ${thisType}`)}
                                                onChange={() => toggleLayerVisibility(cluster.id, thisType)}
                                              />
                                              <label style={{ marginLeft: "8px"}}>{thisType}</label>
                                            </div>
                                          </div>
                                        ))}
                                      </div>
                                    )}
                                  </React.Fragment>
                                ))}
                              </div>
                            )}
                          </React.Fragment>
                        ))}
                      </div>
                    )}
                  </React.Fragment>
                ))}
              </div>
            )}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default LayerMenu;

