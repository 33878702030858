import React, { useState, useEffect, useContext } from 'react';
import { useDropzone } from 'react-dropzone';
import { Storage, API } from 'aws-amplify';
import { useLocation,  withRouter  } from 'react-router-dom';

import { Cell } from 'react-bootstrap-table-next';
import DataTable from 'react-data-table-component';
import EditableCell from '../Shared Component/EditableCell';
import './UploadDocument.css';
import logo from '../Images/rise-broadband-logo.png'
import AppContext from '../Umbrella/Context';

const UploadDocument = () => {

  const location = useLocation();
  const [infoTable, setInfoTable] = React.useState(location.state.infoTable);
  
  const [name, setName] = useState('');
  const [marketArea, setMarketArea] = useState('');
  const [status, setStatus] = useState('');
  const [deleteClusterName, setDeleteClusterName] = useState('');
  const [uploadMessage, setUploadMessage] = useState('');
  const [deleteMessage, setDeleteMessage] = useState('');
  const [porExportDownload, setporExportDownload] = useState('');
  const [clusterNames, setClusterNames] = useState([]);
  const [marketingExportDisabled, setMarketingExportDisabled] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [changeIDs, setChangeIDs] = useState(new Set())
  const [editableInfoTable, setEditableInfoTable] = useState(infoTable);
  const [exportMarketArea, setExportMarketArea] = useState('');

  useEffect(() => {
    fetchLatestData()
  }, []);
    
  const fetchLatestData = async () => {
    const fetchData = async () => {
      try {
        let thisInfoTable = await API.get("tapestryGeo", "/geoInfo");
        thisInfoTable = thisInfoTable.filter((item) => {return item.source_table === 'fiber_por_info' || item.source_table === 'rond_predictions'})
        setInfoTable(thisInfoTable)
        
      } catch (err) {
        console.error(err);
      }
    }
    await fetchData();
  }

  useEffect(() => {
    const names = editableInfoTable.map((item) => ([`${item.market_area} ${item.name}`,item.id]));
    setClusterNames(names);
  }, [editableInfoTable]);

  useEffect(() => {
    if (searchText === "") {
      setEditableInfoTable(infoTable);
    } else {
      const lowerCaseSearchText = searchText.toLowerCase();
      const filtered = infoTable.filter((row) =>
        Object.values(row).some((value) =>
          String(value).toLowerCase().includes(lowerCaseSearchText)
        )
      );
      setEditableInfoTable(filtered);
    }
  }, [searchText, infoTable]);

  const onDrop = async (acceptedFiles) => {
  try {
    if (name.length < 5 || marketArea.length < 5) {
      setUploadMessage('Both Cluster Name and Market Area must be at least 5 characters long.');
      return; // Return early to prevent uploading
    }
    const today = new Date()
    const expireDate = new Date(today)
    expireDate.setDate(expireDate.getDate() + 14)
    
    const result = await Storage.put(acceptedFiles[0].name, acceptedFiles[0], {expires: expireDate})
    
   API.post("tapestryGeo", "/manageFiber", {
  body: { "name": name, "marketArea": marketArea, "status": status, "filePath": "public/".concat(result["key"]) },
}).then(() => {
  setUploadMessage('File successfully uploaded.');
      });
      
  }catch (err) {
    console.error(err);
  }}
  
  const deleteFile = async () => {
    try {
     await API.del("tapestryGeo", "/manageFiber", {
        queryStringParameters: { "id": deleteClusterName }
      });
      setDeleteMessage('File successfully deleted.');
     } catch (err) {
       console.error(err);
     }
  };
  
 const porExport = async () => {
  try {
    const key = await API.get("tapestryGeo", "/csvExport/porDump", {
      queryStringParameters: { market_area: exportMarketArea }
    });
    const fileName = key.objectKey.split("/")[1];
    const url = await Storage.get(key.objectKey);

    const link = document.createElement("a");
    link.download = fileName;
    link.href = url;
    link.click();
  } catch (error) {
    console.error("Error in porExport function:", error);
  }
};


 const marketingExport = async () => {
  try {
    setMarketingExportDisabled(true);
    const key = await API.get("tapestryGeo", "/csvExport/marketingDump", {
      queryStringParameters: { market_area: exportMarketArea }
    });
    const fileName = key.objectKey.split("/")[1];
    const url = await Storage.get(key.objectKey);

    const link = document.createElement("a");
    link.download = fileName;
    link.href = url;
    link.click();
    setTimeout(() => {
      setMarketingExportDisabled(false);
    }, 30000);
  } catch (error) {
    console.error("Error in marketingExport function:", error);
  }
};

const underwriteSummaryExport = async () => {
  try {
    const key = await API.get("tapestryGeo", "/csvExport/underwriteSummary", {
      queryStringParameters: { market_area: exportMarketArea }
    });
    const fileName = key.objectKey.split("/")[1];
    const url = await Storage.get(key.objectKey);

    const link = document.createElement("a");
    link.download = fileName;
    link.href = url;
    link.click();
  } catch (error) {
    console.error("Error in underwriteSummaryExport function:", error);
  }
};

 const columns = [
    { name: "ID", selector: (row) => row.id, sortable: true },
    {
      name: "Cluster Name",
      selector: (row) => row.name,
      sortable: true,
      cell: (row, index, id) => (
        <EditableCell
          value={row.name}
          rowIndex={row.id}
          column={{ selector: "name" }}
          onUpdate={handleCellUpdate}
        />
      ),
    },
    {
      name: "Market Area",
      selector: (row) => row.market_area,
      sortable: true,
      cell: (row, index, id) => (
        <EditableCell
          value={row.market_area}
          rowIndex={row.id}
          column={{ selector: "market_area" }}
          onUpdate={handleCellUpdate}
        />
      ),
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
      cell: (row, index, id) => (
        <EditableCell
          value={row.status}
          rowIndex={row.id}
          column={{ selector: "status" }}
          onUpdate={handleCellUpdate}
        />
      ),
    },
  ];

  const handleCellUpdate = async (rowIndex, column, newValue) => {
    const updatedInfoTable = [...editableInfoTable];
    const thisIndex = updatedInfoTable.findIndex( row => row.id === rowIndex)
    
    updatedInfoTable[thisIndex][column.selector] = newValue;
    setEditableInfoTable(updatedInfoTable);
    try {
      await API.put("tapestryGeo", "/manageFiber", {
        queryStringParameters: {
          id: updatedInfoTable[thisIndex].id,
          name: updatedInfoTable[thisIndex].name,
          market_area: updatedInfoTable[thisIndex].market_area,
          status: updatedInfoTable[thisIndex].status,
        },
      });
      await fetchLatestData();
    } catch (error) {
      console.error("Error saving changes:", error);
      alert("Failed to save changes");
    }
  };
  const filteredData = editableInfoTable.filter((item) => 
  item.name.toLowerCase().includes(searchText.toLowerCase()) ||
  item.market_area.toLowerCase().includes(searchText.toLowerCase()) ||
  item.status.toLowerCase().includes(searchText.toLowerCase()) ||
  String(item.id).toLowerCase().includes(searchText.toLowerCase())
);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
  return (
   <div className="upload-document-container">
   <img src={logo} alt="Logo" className="logo" />
    <div className="content-container">
  <div className="form-group">
    <label>Cluster Name:</label>
    <input
  type="text"
  value={name}
  onChange={(e) => {
    setName(e.target.value);
    setUploadMessage(''); // Clear message
  }}
  placeholder="Enter Cluster Name"
/>
  </div>
  <div className="form-group">
    <label>Market Area:</label>
    <input
  type="text"
  value={marketArea}
  onChange={(e) => {
    setMarketArea(e.target.value);
    setUploadMessage(''); // Clear message
  }}
  placeholder="Enter Market Area"
/>
  </div>
  <div className="form-group">
    <label>Status:</label>
    <select value={status} onChange={(e) => setStatus(e.target.value)}>
      <option value="">Select a Stage</option>
      <option value="Stage 0">Stage 0</option>
      <option value="Stage 1">Stage 1</option>
      <option value="Stage 2">Stage 2</option>
      <option value="Stage 3">Stage 3</option>
    </select>
  </div>
  <div {...getRootProps()} className="dropzone">
    <input {...getInputProps()} />
    {isDragActive ? (
      <p>Drop the FOND plan here...</p>
    ) : (
      <p>Drag and drop a FOND plan here, or click to select a file from your computer</p>
    )}
  </div>
  {uploadMessage && <p className="message">{uploadMessage}</p>}
<div className="action-row">
  <div className="form-group delete-group">
    <label>Delete by Cluster Name:</label>
    <select
      value={deleteClusterName}
      onChange={(e) => setDeleteClusterName(e.target.value)}
    >
      <option value="">Select a Cluster Name</option>
      {clusterNames.map((name, index) => (
        <option key={index} value={name[1]}>
          {name[0]}
        </option>
      ))}
    </select>
    <button onClick={deleteFile}>Delete File</button>
  </div>
  <div className="form-group">
  <label>Export Market Area:</label>
  <select value={exportMarketArea} onChange={(e) => setExportMarketArea(e.target.value)}>
    <option value="">All Market Areas</option>
    {Array.from(new Set(infoTable.map(item => item.market_area))).map((marketArea, index) => (
      <option key={index} value={marketArea}>
        {marketArea}
      </option>
    ))}
  </select>
</div>
  <div className="form-group export-group">
    <button onClick={porExport} value="download">POR Export</button>
    <button onClick={marketingExport} value="download" disabled={marketingExportDisabled}>Marketing Export</button>
    <button onClick={underwriteSummaryExport} value="download">Underwrite Summary</button>
  </div>
 <div className="form-group search-group">
  <label htmlFor="searchDataTable">Search:</label>
  <input
    type="text"
    id="searchDataTable"
    value={searchText}
    onChange={(e) => setSearchText(e.target.value)}
    placeholder="Search data table"
  />
</div>
</div>
{deleteMessage && <p className="message">{deleteMessage}</p>}
  <div className="dataTableContainer">
  <DataTable
  title="Editable Data Table"
  columns={columns}
  data={filteredData}
  keyField='id'
  pagination
  selectableRows
  paginationPerPage={30}
/>
</div>


  {/*<div className="form-group save-group">
    <button onClick={saveChanges}>Save Changes</button>
  </div>*/}
</div>
</div>

  );
};

export default withRouter(UploadDocument)