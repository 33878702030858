import React, { useRef, useState, useEffect, useCallback, useContext } from 'react';
import DeckGL from '@deck.gl/react';
import { StaticMap } from 'react-map-gl';
import { TerrainLayer } from '@deck.gl/geo-layers';
import './DeckGLComponentWithLayers.css';
import ContextProvider from '../Umbrella/ContextProvider';
import AppContext from '../Umbrella/Context';


const DeckGLComponentWithLayers = ({
  viewport,
  layers,
  geocoderContainerRef,
  mapRef,
  MAPBOX_ACCESS_TOKEN,
  terrainEnabled, 
 
  toggleTerrain 
}) => {
  
  const {
    tooltipContent,
    tooltipPosition,
    wedgeTooltipInfo,
    onHover,
    submitWedgeHoverInfo,
    
  } = useContext(AppContext);
  



  return (
    <DeckGL
      initialViewState={viewport}
      controller={{
        doubleClickZoom: false,
      }}
      layers={[...layers]}
      ref={geocoderContainerRef}
      onHover={onHover}
    >
      {wedgeTooltipInfo &&(
        <div
          style={{
            position: "absolute",
            zIndex: 1,
            pointerEvents: "none",
            background: "white",
            padding: "4px",
            left: wedgeTooltipInfo.x,
            top: wedgeTooltipInfo.y,
          }}
        >
          {/* Added null check for wedgeTooltipInfo */}
          {wedgeTooltipInfo?.inobject && (
            <div>
              {Object.keys(wedgeTooltipInfo.inobject).map((key) => (
                <div key={key}>
                  {key}: {wedgeTooltipInfo.inobject[key]}
                </div>
              ))}
            </div>
          )}
        </div>
      )}
       {tooltipContent && tooltipPosition && (
        <div
          style={{
            position: "absolute",
            zIndex: 1,
            background: "white",
            padding: "4px",
            borderRadius: "4px",
            left: tooltipPosition.x,
            top: tooltipPosition.y,
          }}
        >
          {tooltipContent}
        </div>
      )}
      {submitWedgeHoverInfo && (
  <div
    style={{
      position: "absolute",
      zIndex: 1,
      background: "white",
      padding: "4px",
      borderRadius: "4px",
      left: submitWedgeHoverInfo.x,
      top: submitWedgeHoverInfo.y,
    }}
  >
    {Object.keys(submitWedgeHoverInfo.object).map((key) => (
      <div key={key}>
        {key}: {submitWedgeHoverInfo.object[key]}
      </div>
    ))}
  </div>
)}

      <StaticMap
        mapStyle="mapbox://styles/mapbox/satellite-streets-v12"
        mapboxApiAccessToken={MAPBOX_ACCESS_TOKEN}
        ref={mapRef}
      ></StaticMap>
     {/*<button onClick={toggleTerrain}>
  {terrainEnabled ? "Hide Terrain" : "Show Terrain"}
</button>*/}
 
    </DeckGL>
  );
};

export default DeckGLComponentWithLayers;



