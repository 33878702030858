import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import EditableCell from '../Shared Component/EditableCell';
// import { withAuthenticator } from '@aws-amplify/ui-react';
// import { Auth } from 'aws-amplify';


const sampleData = [
  { id: 1, name: 'John Doe', email: 'john.doe@example.com' },
  { id: 2, name: 'Jane Smith', email: 'jane.smith@example.com' },
];

const columns = [
  {
    name: 'ID',
    selector: 'id',
    sortable: true,
  },
  {
    name: 'Name',
    selector: 'name',
    sortable: true,
    cell: (row) => <EditableCell value={row.name} />,
  },
  {
    name: 'Email',
    selector: 'email',
    sortable: true,
    cell: (row) => <EditableCell value={row.email} />,
  },
];

const AdminPage = () => {
  const [selectedFeatures, setSelectedFeatures] = useState(sampleData);
  // const [isAdmin, setIsAdmin] = useState(false);

  // useEffect(() => {
  //   checkAdminStatus();
  // }, []);

  // const checkAdminStatus = async () => {
  //   try {
  //     const user = await Auth.currentAuthenticatedUser();
  //     const { attributes } = user;
  //     // Replace 'custom:isAdmin' with the attribute you use to determine admin status
  //     if (attributes['custom:isAdmin'] === 'true') {
  //       setIsAdmin(true);
  //     } else {
  //       alert('Access denied. You do not have the required permissions to access this page.');
  //     }
  //   } catch (error) {
  //     console.error('Error checking admin status:', error);
  //   }
  // };

  // if (!isAdmin) {
  //   return <div>Access denied. You do not have the required permissions to access this page.</div>;
  // }

  return (
    <div>
      {/* Your admin page content here */}
      <h1>Admin Page</h1>
      <div className="data-table-container">
        <DataTable
          title="Access Layer Info Table"
          columns={columns}
          data={selectedFeatures}
          pagination
          selectableRows
        />
      </div>
    </div>
  );
};

export default AdminPage;