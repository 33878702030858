import React, {useEffect, useRef, useContext, useCallback} from "react";
import ReactDOM from "react-dom";

//Files
import './App.css'
import CustomGeocoderTaptool from './CustomGeocoderTaptool';
import { useExpandedSections} from './useExpandedSections'
import ExpandButton from './ExpandButton'
import LayerMenu from './LayerMenu';
import DeckGLComponent from './DeckGLComponent';
import ButtonLink from './ButtonLink';
import LoadingSpinner from './LoadingSpinner';
import EditableCell from '../Shared Component/EditableCell';
import awsconfig from "../aws-exports"
import { amplifyAPITest, fetchHierarchyData, fetchLatestData } from '../Umbrella/Api.js';
import ContextProvider from '../Umbrella/ContextProvider';
import AppContext from '../Umbrella/Context';

//Libraries
import {GeoJsonLayer} from '@deck.gl/layers';
import "react-map-gl-geocoder/dist/mapbox-gl-geocoder.css";
import { Amplify } from 'aws-amplify'
import { withAuthenticator } from '@aws-amplify/ui-react'
import '@aws-amplify/ui-react/styles.css';
import mbxGeocoding from '@mapbox/mapbox-sdk/services/geocoding';
import DataTable from 'react-data-table-component';
import { Auth } from 'aws-amplify';
import { API } from 'aws-amplify';
import { EditableGeoJsonLayer, ViewMode, ModifyMode, DrawPolygonMode } from 'nebula.gl';

Amplify.configure(awsconfig);

//State Variables 
const MAPBOX_ACCESS_TOKEN =
  "pk.eyJ1IjoiZXN0b2VsemVsIiwiYSI6ImNsaHRnNmgyeTBjajgza3BlcXVmanNkb3AifQ.X47Gk4-fZcII4-3WLR-adw";
const geocodingClient = mbxGeocoding({ accessToken: MAPBOX_ACCESS_TOKEN });
const TapestryTool= ({signOut}) => {
  const {
    listInfoTable, setlistInfoTable, apiData, pointLayers,lineLayers, polygonLayers, hierarchyData, setHierarchyData, isLoading, editlayerId, setEditLayerId,
  } = useContext(AppContext);
  const [features, setFeatures] = React.useState({
    type: "FeatureCollection",
    features: []
  });
  const [visibleLayers, setVisibleLayers] = React.useState([]);
  const [searchResultLayer, setSearchResultLayer] = React.useState(null);
  const [infoTable, setInfoTable] = React.useState([]);
  const [editableInfoTable, setEditableInfoTable] = React.useState([]);
  const [searchText, setSearchText] = React.useState('');
  const [featureCollection, setFeatureCollection] = React.useState(null)
  const [isEditing, setIsEditing] = React.useState([false]);
  
  const [editLayer,setEditLayer] = React.useState(null)
 

  const [visEditIdx,setVisEditIdx] = React.useState(null)
  
  const [searchResults, setSearchResults] = React.useState([]);
  const [visibleIDs, setVisibleIDs] = React.useState(new Set());
  const [userRole, setUserRole] = React.useState('newUser');
  const cleanupFunc = useRef();
//useMemo 
  const [viewport, setViewport] = React.useState(
    {
      width: 400,
      height: 400,
      latitude: 32.7767,
      longitude: -96.809,
      zoom: 8
    })
  const mapRef = useRef(null)
  const geocoderContainerRef = useRef(null)


useEffect(() => {
  if (searchResults.length > 0) {
    setSearchResultLayer(
      new GeoJsonLayer({
        id: "search-result",
        data: {
          type: "FeatureCollection",
          features: searchResults,
        },
        getFillColor: [255, 0, 0, 128],
        getRadius: 1000,
        pointRadiusMinPixels: 10,
        pointRadiusMaxPixels: 10,
      })
    );
  }
}, [searchResults]);

  useEffect(() => {
  const success = (position) => {
    const latitude = position.coords.latitude;
    const longitude = position.coords.longitude;

    // Update the viewport with the new latitude and longitude
    setViewport((prevViewport) => ({
      ...prevViewport,
      latitude,
      longitude,
    }));
  };
  

  const error = () => {
    console.log("Unable to retrieve your location");
  };

  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(success, error);
  } else {
    console.log("Geolocation is not supported by this browser.");
  }
}, []);
useEffect(() => {
  Auth.currentAuthenticatedUser({
    bypassCache: true  
  })
  .then(user => {

    const groups = user.signInUserSession.idToken.payload['cognito:groups']; // get user groups from payload
    if (groups.includes('Engineering')) {
      setUserRole('Engineering');
    } else if (groups.includes('Operations')) {
      setUserRole('Operations');
    } else {
      setUserRole('newUser');
    }
  })
  .catch(err => console.log(err));
}, []);
  useEffect(()=>{
  
    window.addEventListener("resize", resize)
    return ()=>window.removeEventListener("resize", resize)
  }, [])
  
useEffect(() => {
  console.log(editlayerId)
  if (editlayerId !== "" && editlayerId !== undefined) {
    const editLayer = editPolygonLayer(editlayerId);
    setVisibleLayers((prevLayers) => [...prevLayers, editLayer]);
  }
}, [editlayerId]);

const handleGeocoderViewportChange = updatedViewport => {
  setViewport({...viewport, ...updatedViewport})
}
const filterItems = (key, category, item, cluster, thisType) => {
  const searchTerm = searchText.toLowerCase();
  return (
    key.toLowerCase().includes(searchTerm) ||
    category.toLowerCase().includes(searchTerm) ||
    item.toLowerCase().includes(searchTerm) ||
    cluster.name.toLowerCase().includes(searchTerm) ||
    thisType.toLowerCase().includes(searchTerm)
  );
};

const { expandedSections, toggleSection, isSearching, setIsSearching } = useExpandedSections();
  const resize = () => {
    handleGeocoderViewportChange({
      width: window.innerWidth,
      height: window.innerHeight
    })
  }
function getBoundingBox(feature) {
  let west = Infinity;
  let south = Infinity;
  let east = -Infinity;
  let north = -Infinity;

function updateBoundingBox(coordinates) {
    west = Math.min(west, coordinates[0]);
    south = Math.min(south, coordinates[1]);
    east = Math.max(east, coordinates[0]);
    north = Math.max(north, coordinates[1]);
  }

function processGeometry(geometry) {
    if (geometry.type === 'Point') {
      updateBoundingBox(geometry.coordinates);
    } else if (geometry.type === 'MultiPoint' || geometry.type === 'LineString') {
      geometry.coordinates.forEach(updateBoundingBox);
    } else if (geometry.type === 'MultiLineString' || geometry.type === 'Polygon') {
      geometry.coordinates.forEach(coordinates => coordinates.forEach(updateBoundingBox));
    } else if (geometry.type === 'MultiPolygon') {
      geometry.coordinates.forEach(coordinates => coordinates.forEach(coordinates => coordinates.forEach(updateBoundingBox)));
    }
  }

  if (feature.type === 'Feature') {
    processGeometry(feature.geometry);
  } else if (feature.type === 'FeatureCollection') {
    feature.features.forEach(feature => processGeometry(feature.geometry));
  } else {
    processGeometry(feature);
  }

  return [west, south, east, north];
}

function getViewportForBoundingBox([west, south, east, north], viewport) {
  const width = viewport.width;
  const height = viewport.height;

  const longitude = (west + east) / 2;
  const latitude = (south + north) / 2;

  const scale = Math.pow(2, Math.min(
    Math.log2(width / Math.abs(east - west)),
    Math.log2(height / Math.abs(north - south))
  ));

  const zoom = Math.log2(scale) - 8;

  const zoomAdjustment = -5; 
  return {
    ...viewport,
    longitude,
    latitude,
    zoom: zoom - zoomAdjustment
  };
}


const toggleLayerVisibility = (layerId, layerType) => {
  if (!layerId || !layerType) {
    return;
  }
  if (layerType === "fiber") {
    toggleLayerVisibility(layerId, "point");
    toggleLayerVisibility(layerId, "line");
    return;
  }
  const comboLayerID = layerId.concat(" ").concat(layerType);
  const theseVisibleLayers = [];
  const isChecked = visibleIDs.has(comboLayerID);
  if (isChecked) {
    visibleIDs.delete(comboLayerID);
  } else {
    visibleIDs.add(comboLayerID);
    if (!isChecked) {
      const layerMap =
        layerType === "point"
          ? pointLayers
          : layerType === "line"
          ? lineLayers
          : polygonLayers;
      const layer = layerMap.get(layerId);
      if (layer) {
        const boundingBox = getBoundingBox(layer.props.data);
        const newViewport = getViewportForBoundingBox(boundingBox, viewport);
        setViewport(newViewport);
      }
    }
  }
  visibleIDs.forEach((element) => {
    let splitString = element.split(" ");
    const thisLayerType = splitString.slice(-1)[0];
    splitString = splitString.slice(0, 2);
    const recreatedID = splitString.join(" ");
    const layerMap =
      thisLayerType === "point"
        ? pointLayers
        : thisLayerType === "line"
        ? lineLayers
        : polygonLayers;

    // Get layer from map and handle potential errors
    // console.log(layerMap)
    let layer = layerMap.get(recreatedID);
    if (layer) {
      const layerClone = layer.clone();

      // Add isChecked property to the cloned layer
      layerClone.isChecked = comboLayerID === element ? isChecked : !isChecked;

      theseVisibleLayers.push(layerClone);
    } else {
      console.error(`Layer not found for id: ${recreatedID}`);
    }
  });
  setVisibleLayers(theseVisibleLayers);
};


const toggleLayersInHierarchy = (obj) => {
  if (typeof obj === "object" && obj !== null) {
    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        if (typeof obj[key] === "object" && obj[key] !== null) {
          toggleLayersInHierarchy(obj[key]);
        } else {
          if (key === "id" && typeof obj[key] === "string") {
            //console.log(obj[key]);
            if (obj[key].split(" ")[1] === "fiber_por_info") {
              toggleLayerVisibility(obj[key], "fiber");
            } else {
              toggleLayerVisibility(obj[key], "polygon");
              
            }
          }
        }
      }
    }
  }
};

function toggleLayerMenu() {
  const layerMenu = document.getElementById("layerMenu");
  layerMenu.style.display = layerMenu.style.display === "none" ? "block" : "none";
}

// const editPolygonLayer = (layerId) => {
  
//   console.log(layerId)
//   const layer = polygonLayers.get(layerId)
  
//   let editData = layer.props.data
//   console.log(layer)
  
//   const editLayer = layer.clone({
//     id: 'editing-layer',
//     data: editData,
//     mode: new ModifyMode(),
//     selectedFeatureIndexes: [0],
//     autoHighlight: true,
//     onEdit: ({ updatedData }) => {
//       editData = updatedData
//     }
//   });
  
//   return editLayer
  
// }

const deckRef = useRef(null);

const onClick = useCallback(event => {
  if (event.layer !== null && event.layer.props.mode !== ModifyMode) {
    const idx = visibleLayers.indexOf(event.layer);
    setVisEditIdx(idx);
    const newFeatureCollection = event.layer.props.data;
    setFeatureCollection(newFeatureCollection);

    if (newFeatureCollection !== null) {
      if (visibleLayers[idx]) {
        const thisEditableLayer = visibleLayers[idx].clone({
          id: 'editableLayer',
          data: newFeatureCollection,
          mode: new ModifyMode(),
          pickable: true,
          autoHighlight: true,
          selectedFeatureIndexes: [0],
          onEdit: ({ updatedData }) => {
            console.log(updatedData);
            setFeatureCollection(updatedData);
          }
        });
        const updateLayers = [...visibleLayers];
        updateLayers[idx] = thisEditableLayer;
        setVisibleLayers(updateLayers);
        setEditLayer(thisEditableLayer);
      }
    }
  }
}, [visEditIdx, featureCollection]);

useEffect(() => {
  if(featureCollection && visEditIdx && visEditIdx !== -1){
    const cloneLayer = visibleLayers[visEditIdx].clone({data: featureCollection})
    const updateLayers = visibleLayers
    updateLayers[visEditIdx] = cloneLayer
    setVisibleLayers(updateLayers)
  }
  
}, [featureCollection,visEditIdx,visibleLayers])

useEffect(() => {
  console.log(visEditIdx)
  if(typeof visEditIdx === 'number' && visEditIdx !== -1) {
    const thisEditableLayer = visibleLayers[visEditIdx].clone({
        id: 'editableLayer',
        // data: isEditing[0] ? featureCollection[0] : event.layer.props.data,
        data: featureCollection,
        mode: new ModifyMode(),
        pickable: true,
        autoHighlight: true,
        selectedFeatureIndexes: [0],
        onEdit: ({ updatedData }) => {
          console.log(updatedData)
          setFeatureCollection(updatedData);
        }
      })
      
      const updateLayers = visibleLayers
      updateLayers[visEditIdx] = thisEditableLayer
      setVisibleLayers(updateLayers)
  }
  
}, [visEditIdx])




// const onClick = useCallback(event => {
//   if (event.layer !== null) {
//     console.log(event);
    
    
//       const thisEditableLayer = event.layer.clone({
//         id: 'editableLayer',
//         data: event.layer.props.data,
//         mode: new ModifyMode(),
//         pickable: true,
//         autoHighlight: true,
//         selectedFeatureIndexes: [0],
//         onEdit: ({ updatedData }) => {
//           setFeatureCollection(updatedData);
//         }
//       })
      
//       setFeatureCollection(event.layer.props.data)
//       setEditableLayer(thisEditableLayer)
//       setVisibleLayers([...visibleLayers, editableLayer]);
//       console.log(editableLayer)
//   }
// }, [visibleLayers,featureCollection,editableLayer])

// useEffect(() => {
//   if (editableLayer) {
//     editableLayer.data = featureCollection
//     console.log('Editable layer has been updated:', editableLayer);
//   }
// }, [visibleLayers,featureCollection,editableLayer]);



const editPolygonLayer = (layerId) => {
  console.log(layerId)
  const layer = polygonLayers.get(layerId)
  
  let editData = layer.props.data
  console.log(layer)
  
  if (editData && editData.type === 'FeatureCollection') {
    const editLayer = layer.clone({
      id: 'editing-layer',
      data: editData,
      mode: new ModifyMode(),
      selectedFeatureIndexes: [0],
      autoHighlight: true,
      onEdit: ({ updatedData }) => {
        editData = updatedData
      }
    });
    
    return editLayer
  } else {
    console.error('Invalid editData:', editData);
    return null;
  }
}
const handleGeocoderSearch = (selectedLocation) => {
    const [longitude, latitude] = selectedLocation.geometry.coordinates;
    setViewport({
      ...viewport,
      latitude,
      longitude,
      zoom: 14,
    });
  };
  const columns = [
    { name: "ID", selector: (row) => row.id, sortable: true },
    {
      name: "Cluster Name",
      selector: (row) => row.name,
      sortable: true,
      cell: (row, index, id) => (
        <EditableCell
          value={row.name}
          rowIndex={row.id}
          column={{ selector: "name" }}
          onUpdate={handleCellUpdate}
        />
      ),
    },
    {
      name: "Market Area",
      selector: (row) => row.market_area,
      sortable: true,
      cell: (row, index, id) => (
        <EditableCell
          value={row.market_area}
          rowIndex={row.id}
          column={{ selector: "market_area" }}
          onUpdate={handleCellUpdate}
        />
      ),
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
      cell: (row, index, id) => (
        <EditableCell
          value={row.status}
          rowIndex={row.id}
          column={{ selector: "status" }}
          onUpdate={handleCellUpdate}
        />
      ),
    },
  ];

  useEffect(() => {
  const fetchData = async () => {
    try {
      const { thisInfoTable, hierarchyData } = await fetchLatestData();
      setInfoTable(thisInfoTable);
      setEditableInfoTable(thisInfoTable);
      setHierarchyData(hierarchyData);
    } catch (err) {
      console.error(err);
    }
  };

  fetchData();
}, []);

const handleCellUpdate = async (rowIndex, column, newValue) => {
    const updatedInfoTable = [...editableInfoTable];
    const thisIndex = updatedInfoTable.findIndex( row => row.id === rowIndex)
    updatedInfoTable[thisIndex][column.selector] = newValue;
    setEditableInfoTable(updatedInfoTable);
    try {
      await API.put("tapestryGeo", "/manageFiber", {
        queryStringParameters: {
          id: updatedInfoTable[thisIndex].id,
          name: updatedInfoTable[thisIndex].name,
          market_area: updatedInfoTable[thisIndex].market_area,
          status: updatedInfoTable[thisIndex].status,
        },
      });
      await fetchLatestData();
    } catch (error) {
      console.error("Error saving changes:", error);
      alert("Failed to save changes");
    }
  };
  const filteredData = editableInfoTable.filter((item) => 
  item.name.toLowerCase().includes(searchText.toLowerCase()) ||
  item.market_area.toLowerCase().includes(searchText.toLowerCase()) ||
  item.status.toLowerCase().includes(searchText.toLowerCase()) ||
  String(item.id).toLowerCase().includes(searchText.toLowerCase())
);
 const handleSearch = (e) => {
    setSearchText(e.target.value);
  };
return (
    
    <>
     <div className="app-container">
    <div className="geocoder-container1">
      <CustomGeocoderTaptool onSearch={handleGeocoderSearch} placeholder="Search location" />
    </div>
     <ExpandButton onClick={toggleLayerMenu} />
  <LayerMenu
  hierarchyData={hierarchyData}
  filterItems={filterItems}
  expandedSections={expandedSections}
  isSearching={isSearching}
  toggleSection={toggleSection}
  toggleLayersInHierarchy={toggleLayersInHierarchy}
  visibleIDs={visibleIDs}
  toggleLayerVisibility={toggleLayerVisibility}
/>
 <DeckGLComponent
  ref={deckRef}
  viewport={viewport}
  visibleLayers={visibleLayers}
  geocoderContainerRef={geocoderContainerRef}
  mapRef={mapRef}
  MAPBOX_ACCESS_TOKEN={MAPBOX_ACCESS_TOKEN}
  getTooltip={({object}) => object && Object.entries(object.properties).map(([key, value]) => `${key}: ${value}`).join('\n')}
  onLayerClick={onClick}
/>
</div>
<input
        type="text"
        placeholder="Search"
        value={searchText}
        onChange={handleSearch}
      />
      
      <DataTable
        keyField='id' // assuming 'id' uniquely identifies each row
        columns={columns}
        data={filteredData}
        defaultSortField="id"
        pagination
        selectableRows
        paginationPerPage={30}
      />

{userRole === 'Engineering' && (
  <div style={{ position: 'absolute', top: 0, right: 0, zIndex: 1000 }}>
        <ButtonLink
  pathname="/upload"
  state={{
    infoTable: listInfoTable.filter((item) => {return item.source_table === 'fiber_por_info' || item.source_table === 'rond_predictions'}),
    updateInfoTable: infoTable,
  }}
  buttonText="MENU"
/>
      </div>
      )}
      <LoadingSpinner isLoading={isLoading} />
     </>
       
    
  );
 
}
export default TapestryTool

