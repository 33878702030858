import { useState } from 'react';

export const useExpandedSections = (searchText) => {
  const [expandedSections, setExpandedSections] = useState({});
  const [isSearching, setIsSearching] = useState(false);

  const toggleSection = (id) => {
    setExpandedSections((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  return { expandedSections, toggleSection, isSearching, setIsSearching };
};
