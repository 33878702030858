import React from 'react';

const LoadingSpinner = ({ isLoading }) => {
  return (
    isLoading && (
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          zIndex: 2000,
          background: 'rgba(255, 255, 255, 0.8)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div className="spinner" />
      </div>
    )
  );
};

export default LoadingSpinner;
