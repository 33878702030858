import React, { useRef, useState, useEffect, useCallback, useContext } from 'react';
import Popover from '@material-ui/core/Popover';
import DeckGLComponentWithLayers from './DeckGLComponentWithLayers';
import CustomGeocoder from './CustomGeocoder';
import WedgeInputModal from './WedgeInputModal'
import SessionList from './SessionList'
import ContextMenu from './Contextmenu'
import './AccessLayer.css';
import DataTable from 'react-data-table-component';
import EditableCell from '../Shared Component/EditableCell';
import { GeoJsonLayer, IconLayer, ScatterplotLayer, BitmapLayer } from '@deck.gl/layers';
import { BrushingExtension } from '@deck.gl/extensions';
import { toPng } from 'html-to-image';
import ReactDOMServer from 'react-dom/server';
import Tower from '../Icons/Tower'
import { WebMercatorViewport } from '@deck.gl/core';
import { API } from 'aws-amplify';
import {  ModifyMode, ViewMode,RotateMode } from 'nebula.gl';
import { COORDINATE_SYSTEM } from '@deck.gl/core';
import { Matrix4 } from 'math.gl';
import { Auth } from 'aws-amplify'; 
import { Link } from 'react-router-dom';
import './Button.css';
import { Editor as RMGLEditor} from 'react-map-gl-draw';

import { arcgisToGeoJSON } from "@terraformer/arcgis"
import Switch from '@material-ui/core/Switch';
import ContextProvider from '../Umbrella/ContextProvider';
import AppContext from '../Umbrella/Context';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Slider from '@material-ui/core/Slider';
import { ContextMenuTrigger } from "react-contextmenu";
import { DrawPolygonMode } from 'nebula.gl';
import axios from 'axios';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import { LineLayer } from '@deck.gl/layers';
// import FakeData from './3dLines.json'







const AccessLayerTool = () => {
  const [tooltipInfo, setTooltipInfo] = React.useState(null);
  const editorRef = useRef();
 const [currentMode, setCurrentMode] = useState(new ViewMode());
 const [polygonData, setPolygonData] = React.useState([]);
const [isRotating, setIsRotating] = useState(false);
const [terrainEnabled, setTerrainEnabled] = useState(false);
const [isWedgeModalOpen, setWedgeModalOpen] = useState(false);
const [selectedTower, setSelectedTower] = useState(null);
 const {layers, layerVisibility,fetchDataAndUpdateLayers,toggleLayerVisibility,
   onHover, rowData,selectedRow, updateSelectedRow,setSelectedAttribute,
   setHeatmapScaleFactor,heatmapScaleFactor,populateWedges,clearWedges, handleOptionChange, selectedOption, toggleAccessMode, toggleLassoMode,
    isLassoModeEnabled, drawPolygonMode, setLayers, planId, setPlanId, savedWedges, setSavedWedges, lineLayerVisible, setLineLayerVisible,
    lineData, setLineData,isWedgesLoading, setIsWedgesLoading,toggleFocusedLayersVisibility} = React.useContext(AppContext);
    
    
 const [tooltipContent, setTooltipContent] = useState(null);
const [tooltipPosition, setTooltipPosition] = useState(null);
const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const mapRef = useRef();
  const geocoderContainerRef = useRef();
const [apiResponseData, setApiResponseData] = useState([]);
const [removedColumns, setRemovedColumns] = useState([]);
const [planName, setPlanName] = useState('');
const [planNotes, setPlanNotes] = useState('');
const [sessionListData, setSessionListData] = useState({});
const [openDialog, setOpenDialog] = React.useState(false);
const [inputPlanName, setInputPlanName] = React.useState('');
const [inputPlanNotes, setInputPlanNotes] = React.useState('');



const [sessionLayers, setSessionLayers] = useState([]);


   const [viewport, setViewport] = useState({
    latitude: 37.7749,
    longitude: -122.4194,
    zoom: 10,
    bearing: 0,
    pitch: 0,
  });

  // Function to update the viewport to the user's current location
  const setUserLocation = () => {
    navigator.geolocation.getCurrentPosition((position) => {
      const { latitude, longitude } = position.coords;
      setViewport((prevViewport) => ({
        ...prevViewport,
        latitude,
        longitude,
        zoom: 14,
      }));
    });
  };
  


  // Use useEffect to call setUserLocation on component mount
  useEffect(() => {
    setUserLocation();
  }, []);
  
  useEffect(() => {
  fetchDataAndUpdateLayers(layerVisibility);
}, [polygonData, isRotating, layerVisibility]);

useEffect(() => {
  if (editorRef.current) {
    editorRef.current.setMode(currentMode);
  }
}, [currentMode]);

useEffect(() => {
  if (selectedRow) {
    const { Lat, Long } = selectedRow; // Replace these keys with the actual keys in your row data
    if (typeof Lat === 'number' && typeof Long === 'number') {
      const viewport = new WebMercatorViewport({
        width: 856,
        height: 486,
      });
      const { longitude, latitude, zoom } = viewport.fitBounds([[Long, Lat], [Long, Lat]]);
      setViewport({
        ...viewport,
        latitude,
        longitude,
        zoom,
      });
    } else {
      console.error('Invalid Lat or Long in selected row');
    }
  }
}, [selectedRow]);

const toggleLineLayerVisibility = () => {
    console.log("Toggling Line Layer Visibility", !lineLayerVisible); // Debugging line
    setLineLayerVisible(!lineLayerVisible);
  };


// const lineLayer = new LineLayer({
//     id: '3d-line-layer',
//     data: lineData,
//     getWidth: 5,
//     getColor: [255, 0, 0, 200],
//     widthMinPixels: 2,
//     visible: true,
//     pickable: true,
//   });

//   useEffect(() => {
//     if (lineLayerVisible) {
//       setLayers((prevLayers) => [...prevLayers, lineLayer]);
//     } else {
//       setLayers((prevLayers) => prevLayers.filter((layer) => layer.id !== '3d-line-layer'));
//     }
//   }, [lineLayerVisible, lineData]);
  
const handleSliderChange = (event, newValue) => {
    setHeatmapScaleFactor(newValue / 100); // Convert back to decimal
  };

  const handleTowerClick = (info) => {
    if (info && info.object && info.object.coordinates) {
      setSelectedTower(info.object);
      setWedgeModalOpen(true);
    } else {
      console.error("Tower info or coordinates are null");
    }
  };

const onRowClicked = (event) => {
  updateSelectedRow(event.data);
};
const onGridReady = (params) => {
  setGridApi(params.api);
  setGridColumnApi(params.columnApi);

  // Automatically adjust the column sizes
  const allColumnIds = [];
  params.columnApi.getAllColumns().forEach(column => {
    allColumnIds.push(column.colId);
  });
  params.columnApi.autoSizeColumns(allColumnIds, false);
};

  const removeColumn = (colId) => {
    if (!removedColumns.includes(colId)) {
      setRemovedColumns([...removedColumns, colId]);
      if (gridColumnApi) {
        gridColumnApi.setColumnVisible(colId, false);
      }
    }
  };

  // Modified restoreColumn function
  const restoreColumn = (colId) => {
    if (gridColumnApi) {
      gridColumnApi.setColumnVisible(colId, true);
      setRemovedColumns(removedColumns.filter(c => c !== colId));
    }
  };

  const MAPBOX_ACCESS_TOKEN =
    "pk.eyJ1IjoiZXN0b2VsemVsIiwiYSI6ImNsaHRnNmgyeTBjajgza3BlcXVmanNkb3AifQ.X47Gk4-fZcII4-3WLR-adw";

  const handleGeocoderSearch = (selectedLocation) => {
    const [longitude, latitude] = selectedLocation.geometry.coordinates;
    setViewport({
      ...viewport,
      latitude,
      longitude,
      zoom: 14,
    });
  };

const handleAttributeChange = (newAttribute) => {
  setSelectedAttribute(newAttribute);
};

const handleStartDrawingClick = () => {
  toggleAccessMode()
  // toggleLayerVisibility(['drawn-polygon-layer']);
  };



const handleShowSession = (sessionLayer) => {
  if (sessionLayer === null) {
    setLayers(prevLayers => prevLayers.filter(layer => !layer.id.startsWith('session-layer-')));
  } else {
    setLayers(prevLayers => [...prevLayers.filter(layer => !layer.id.startsWith('session-layer-')), sessionLayer]);
  }
};


  const handleOpenDialog = () => {
  setOpenDialog(true);
};

const handleCloseDialog = () => {
  setOpenDialog(false);
};

const handleSavePlan = async () => {
  const planData = {
     planid: planId,
    planname: inputPlanName,
    notes: inputPlanNotes,
  };

  try {
  
    const saveResponse = await API.post('tapestryGeo', '/accesstool/manageplans/saveplan', 
    { body: planData });
    if (saveResponse && saveResponse.status === 200) {
      await updateSessionListData();
    } else {
      
      console.error('Save plan response:', saveResponse);
    }
    
    setInputPlanName('');
    setInputPlanNotes('');
    setOpenDialog(false);
  } catch (error) {
    console.error('Error saving the plan: ', error);

    if (error.response) {

      console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
    
      console.log(error.request);
    } else {
  
      console.log('Error', error.message);
    }
  }
};

const updateSessionListData = async () => {

  try {
    const user = await Auth.currentAuthenticatedUser();
    const username = user.attributes.email;
    const response = await API.get('tapestryGeo', '/accesstool/manageplans/listplans', {
      queryStringParameters: { "username": username },
      timeout: 60000,
    });
    
   
    setSessionListData(response);
  } catch (error) {
    console.error('Error fetching updated session list data', error);
  }
 
};



  return (
    
    <div>
      <ContextMenuTrigger id="wedge-context-menu">
      <div className="geocoder-container">
        <CustomGeocoder onSearch={handleGeocoderSearch} placeholder="Search location" />
      </div>
      <div className="map-container">
                <DeckGLComponentWithLayers
          viewport={viewport}
          layers={layers}
          geocoderContainerRef={geocoderContainerRef}
          mapRef={mapRef}
          onClick={handleTowerClick}
          MAPBOX_ACCESS_TOKEN={MAPBOX_ACCESS_TOKEN}
          tooltipInfo={tooltipInfo}
          tooltipContent={tooltipContent} // Pass the tooltip content as a prop
          tooltipPosition={tooltipPosition}
          onHover={onHover}
        />
      </div>
<div className="toggle-buttons">
  <div className="toggle-item">
    <label>Viewshed</label>
    <Switch
      checked={layerVisibility?.['viewshed-layer'] ?? false}
      onChange={() => toggleLayerVisibility('viewshed-layer')}
    />
  </div>
  
  <div className="toggle-item">
    <label>Towers</label>
    <Switch
      checked={layerVisibility?.['tower-layer-1'] ?? false}
      onChange={() => toggleLayerVisibility('tower-layer-1')}
    />
  </div>
  
  <div className="toggle-item">
      <label>Wireless Network</label>
      <Switch
        checked={layerVisibility?.['network-layer'] ?? false}
        onChange={() => toggleLayerVisibility('network-layer')}
      />
    </div>
    <div className="toggle-item">
    <label>Building Polygon</label>
    <Switch
      checked={layerVisibility?.['building-polygon-layer'] ?? false}
      onChange={() => toggleLayerVisibility('building-polygon-layer')}
    />
  </div>
  <div className="toggle-item">
    <label>Populated Wedges</label>
    <Switch
      checked={layerVisibility?.['wedge-layer'] ?? false}
      onChange={() => toggleLayerVisibility('wedge-layer')}
    />
  </div>
   <div className="toggle-item">
    <label>Focused Tower</label>
    <Switch
      checked={layerVisibility['focused-towers-layer'] || layerVisibility['focused-ap-layer']}
      onChange={() => toggleFocusedLayersVisibility()}
    />
  </div>
  
  
  <div className="toggle-item">
    <label>Reload Layer</label>
    <Switch
      checked={layerVisibility?.['reload-layer'] ?? false}
      onChange={() => toggleLayerVisibility('reload-layer')}
    />
  </div>
    <div className="toggle-item">
          <label>Census Blocks</label>
          <Switch
            checked={layerVisibility['census-block-layer']}
            onChange={() => toggleLayerVisibility('census-block-layer')}
          />
        </div>
          <div className="dropdown-container">
    <Select 
      defaultValue={'marketing_headroom'}
      onChange={(e) => handleAttributeChange(e.target.value)}
    >
      <MenuItem value={'marketing_headroom'}>Marketing Headroom</MenuItem>
      <MenuItem value={'hu_100'}>HU 100</MenuItem>
      <MenuItem value={'hu_500'}>HU 500</MenuItem>
      <MenuItem value={'subs'}>Subs</MenuItem>
    </Select>
  </div>
        <div style={{ margin: '10px' }}>
            <label>Heatmap Scale Factor: {Math.round(heatmapScaleFactor * 100)}%</label>
            <Slider
              value={heatmapScaleFactor * 100} // Convert to percentage for display
              onChange={handleSliderChange}
              min={0}
              max={100}
              step={0.01}
            />
          </div>
          <div className="button-group">
           <button 
    className={`uniform-button ${isWedgesLoading ? 'loading' : ''}`} 
    onClick={populateWedges} 
    disabled={isWedgesLoading}
  >
    Pop Wedge
  </button>
          <button className="uniform-button" onClick={clearWedges}>Clear</button>
             
        </div>
        
      </div>
       <div className="toggle-item-draw">
      <button onClick={toggleAccessMode}>
        {drawPolygonMode ? 'Disable Lasso' : 'Enable Lasso'}
      </button>
        <select  onChange= {handleOptionChange} value={selectedOption}>
          <option value="allTarana">Tarana</option>
          <option value="all450M">450M</option>
        </select>
        {/* <button onClick={toggleLineLayerVisibility}>
        {lineLayerVisible ? 'Hide 3D Lines' : 'Show 3D Lines'}
      </button>*/}
      </div>
      <div>
       <SessionList
  onShowSession={handleShowSession}
  sessionListData={sessionListData}
   setViewport={setViewport}
   setSessionListData={setSessionListData}
/>
    
      {layerVisibility['wedge-layer'] && (
          <button className="save-plan-button"onClick={handleOpenDialog}>
            Save Plan
          </button>
        )}
        <Dialog open={openDialog} onClose={handleCloseDialog} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Save Plan</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="plan-name"
          label="Plan Name"
          type="text"
          fullWidth
          value={inputPlanName}
          onChange={(e) => setInputPlanName(e.target.value)}
        />
        <TextField
          margin="dense"
          id="plan-notes"
          label="Notes"
          type="text"
          fullWidth
          multiline
          rows={4}
          value={inputPlanNotes}
          onChange={(e) => setInputPlanNotes(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseDialog} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSavePlan} color="primary">
          Save Plan
        </Button>
      </DialogActions>
    </Dialog>
  </div>

      </ContextMenuTrigger>
      <div id="toolbar">
        {removedColumns.length > 0 ? (
          removedColumns.map(colId => (
            <div key={colId}
                 draggable
                 onDragStart={e => e.dataTransfer.setData("colId", colId)}>
              {colId}
            </div>
          ))
        ) : (
          <div className="placeholder">Drag here to set row groups</div>
      )}
    </div>
<div className="ag-theme-alpine" style={{ height: '300px', width: '100%' }}onDrop={e => {
          e.preventDefault();
          const colId = e.dataTransfer.getData("colId");
          restoreColumn(colId);
        }}
        onDragOver={e => e.preventDefault()}
      >
        <AgGridReact
         onGridReady={onGridReady}
  const columnDefs = {[
  { headerName: "Tower", field: "Tower", sortable: true, filter: 'agTextColumnFilter',minWidth: 150  },
  { headerName: "Sector Name", field: "Sector Name", sortable: true, filter: 'agTextColumnFilter',minWidth: 150  },
  { headerName: "coveredBy", field: "coveredBy", sortable: true, filter: 'agTextColumnFilter',minWidth: 150  },
  { headerName: "Priority", field: "Priority", sortable: true, filter: 'agNumberColumnFilter',minWidth: 150  },
  { headerName: "Tech", field: "Tech", sortable: true, filter: 'agTextColumnFilter', minWidth: 150  },
  { headerName: "Radius Override", field: "Radius Override", sortable: true, filter: 'agNumberColumnFilter',minWidth: 200  },
  { headerName: "Azimuth", field: "Azimuth", sortable: true, filter: 'agNumberColumnFilter',minWidth: 150  },
  { headerName: "Beamwidth", field: "Beamwidth", sortable: true, filter: 'agNumberColumnFilter',minWidth: 150  },
  { headerName: "headroom3Mile", field: "headroom3Mile", sortable: true, filter: 'agNumberColumnFilter',minWidth: 150  },
  { headerName: "customers3Mile", field: "customers3Mile", sortable: true, filter: 'agNumberColumnFilter',minWidth: 200  },
  { headerName: "total3Mile", field: "total3Mile", sortable: true, filter: 'agNumberColumnFilter',minWidth: 150  },
  { headerName: "headroom8Mile", field: "headroom8Mile", sortable: true, filter: 'agNumberColumnFilter',minWidth: 150  },
  { headerName: "customers8Mile", field: "customers8Mile", sortable: true, filter: 'agNumberColumnFilter',minWidth: 200  },
  { headerName: "total8Mile", field: "total8Mile", sortable: true, filter: 'agNumberColumnFilter',minWidth: 150  },
  { headerName: "headroomOverride", field: "headroomOverride", sortable: true, filter: 'agNumberColumnFilter',minWidth: 200  },
  { headerName: "customersOverride", field: "customersOverride", sortable: true, filter: 'agNumberColumnFilter',minWidth: 200  },
  { headerName: "totalIndividualCoverage", field: "totalIndividualCoverage", sortable: true, filter: 'agNumberColumnFilter',minWidth: 200  },
  { headerName: "uniqueHeadroom", field: "uniqueHeadroom", sortable: true, filter: 'agNumberColumnFilter',minWidth: 200  },
  { headerName: "uniqueCustomers", field: "uniqueCustomers", sortable: true, filter: 'agNumberColumnFilter',minWidth: 200  },
  { headerName: "doubleCoverHeadroom", field: "doubleCoverHeadroom", sortable: true, filter: 'agNumberColumnFilter',minWidth: 200  },
  { headerName: "doubleCoverCustomers", field: "doubleCoverCustomers", sortable: true, filter: 'agNumberColumnFilter',minWidth: 200  },
  { headerName: "uniqueHeadroomPlusClosest", field: "uniqueHeadroomPlusClosest", sortable: true, filter: 'agNumberColumnFilter',minWidth: 250  },
  { headerName: "uniqueCustomersPlusClosest", field: "uniqueCustomersPlusClosest", sortable: true, filter: 'agNumberColumnFilter',minWidth: 250  },
  { headerName: "totalIndividualHeadroom", field: "totalIndividualHeadroom", sortable: true, filter: 'agNumberColumnFilter',minWidth: 250  },
  { headerName: "totalIndividualCustomers", field: "totalIndividualCustomers", sortable: true, filter: 'agNumberColumnFilter',minWidth: 250  },
]}
          rowData={rowData}
          onRowClicked={onRowClicked}
          onColumnVisible={params => {
    if (!params.visible) {
      removeColumn(params.column.colId);
    }
  }}
  floatingFilter={true} // Enable floating filters

  defaultColDef={{
    flex: 1,
    minWidth: 100,
    filter: true,
    resizable: true,
    sortable: true,
    floatingFilterComponentParams: { suppressFilterButton: true },
  }}
>
        </AgGridReact>
       
      </div>
    </div>
    
  );
};

export default AccessLayerTool

