import React, { useEffect, useContext, useState } from "react";
import DeckGL from '@deck.gl/react';
import { GeoJsonLayer } from '@deck.gl/layers';

import { StaticMap } from 'react-map-gl';
import DataTable from 'react-data-table-component';
import CustomGeocoderTaptool from '../Tapestry Tool/CustomGeocoderTaptool';
import AppContext from '../Umbrella/Context';
import { fetch3GisLayer } from '../Umbrella/3Gis';



const DetailDesignTool = () => {
  const { editableInfoTable } = useContext(AppContext);
  const [viewport, setViewport] = useState({
    width: 400,
    height: 400,
    latitude: 32.7767,
    longitude: -96.809,
    zoom: 8
  });
  const [spanData, setSpanData] = useState({
    type: 'FeatureCollection',
    features: [],
  });
  const [fiberCableData, setFiberCableData] = useState({
    type: 'FeatureCollection',
    features: [],
  });
  const [fiberEquipmentData, setFiberEquipmentData] = useState({
    type: 'FeatureCollection',
    features: [],
  });
  const [spliceClosureData, setSpliceClosureData] = useState({
    type: 'FeatureCollection',
    features: [],
  });
  const [structureData, setStructureData] = useState({
    type: 'FeatureCollection',
    features: [],
  });
  const [buildingData, setBuildingData] = useState({
    type: 'FeatureCollection',
    features: [],
  });
  const [addressPointData, setAddressPointData] = useState({
    type: 'FeatureCollection',
    features: [],
  });
  
  const MAPBOX_ACCESS_TOKEN =
  "pk.eyJ1IjoiZXN0b2VsemVsIiwiYSI6ImNsaHRnNmgyeTBjajgza3BlcXVmanNkb3AifQ.X47Gk4-fZcII4-3WLR-adw";
  
   useEffect(() => {
    fetch3GisLayer("https://arcgis.risebroadband.com/arcgis/rest/services/3GIS/Telecom/MapServer/12/query", ["inventory_status_code",
                                                                                                              "install_method",
                                                                                                              "diameter",
                                                                                                              "measuredlength",
                                                                                                              "rbid",
                                                                                                              "material_cd",
                                                                                                              "mat_description",
                                                                                                              "marketid",
                                                                                                              "ductcount"])
      .then(fetchedData => {
        setSpanData(fetchedData);
      })
      
      fetch3GisLayer("https://arcgis.risebroadband.com/arcgis/rest/services/3GIS/Telecom/MapServer/9/query", ["cable_category",
                                                                                                              "inventory_status_code",
                                                                                                              "measuredlength",
                                                                                                              "placementtype",
                                                                                                              "rbid",
                                                                                                              "fibercount",
                                                                                                              "manufacturer",
                                                                                                              "admincount",
                                                                                                              "mat_description",
                                                                                                              "actual_length",
                                                                                                              "marketid"])
      .then(fetchedData => {
        setFiberCableData(fetchedData);
      });
      
      fetch3GisLayer("https://arcgis.risebroadband.com/arcgis/rest/services/3GIS/Telecom/MapServer/0/query", ["manufacturer",
                                                                                                              "modelnumber",
                                                                                                              "equipmentname",
                                                                                                              "rbid",
                                                                                                              "material_cd",
                                                                                                              "mat_description",
                                                                                                              "inventory_status_code",
                                                                                                              "marketid"])
      .then(fetchedData => {
        setFiberEquipmentData(fetchedData);
      });
      
      fetch3GisLayer("https://arcgis.risebroadband.com/arcgis/rest/services/3GIS/Telecom/MapServer/1/query", ["inventory_status_code",
                                                                                                              "manufacturer",
                                                                                                              "modelnumber",
                                                                                                              "rbid",
                                                                                                              "mat_description",
                                                                                                              "marketid",
                                                                                                              "admincounts"])
      .then(fetchedData => {
        setSpliceClosureData(fetchedData);
      });
      
      fetch3GisLayer("https://arcgis.risebroadband.com/arcgis/rest/services/3GIS/Telecom/MapServer/7/query", ["subtypecode",
                                                                                                              "address",
                                                                                                              "city",
                                                                                                              "state",
                                                                                                              "zipcode",
                                                                                                              "rbid",
                                                                                                              "marketid"])
      .then(fetchedData => {
        setBuildingData(fetchedData);
      });
      
      fetch3GisLayer("https://arcgis.risebroadband.com/arcgis/rest/services/3GIS/Telecom/MapServer/3/query", ["subtypecode",
                                                                                                              "structure_size",
                                                                                                              "material",
                                                                                                              "manufacturer",
                                                                                                              "rbid",
                                                                                                              "material_cd",
                                                                                                              "marketid",
                                                                                                              "mat_description",
                                                                                                              "inventory_status_code",
                                                                                                              "rating"])
      .then(fetchedData => {
        setStructureData(fetchedData);
      });
      
      fetch3GisLayer("https://arcgis.risebroadband.com/arcgis/rest/services/3GIS/Telecom/MapServer/14/query", ["addresstype",
                                                                                                              "house_number",
                                                                                                              "unitnumber",
                                                                                                              "street_direction",
                                                                                                              "street_suffix",
                                                                                                              "city",
                                                                                                              "state",
                                                                                                              "zipcode",
                                                                                                              "streetname"])
      .then(fetchedData => {
        setAddressPointData(fetchedData);
      })
  }, []);
  
  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const spanData = await fetch3GisLayer("https://arcgis.risebroadband.com/arcgis/rest/services/3GIS/Telecom/MapServer/12/query", ["inventory_status_code", "install_method", "diameter", "measuredlength", "rbid", "material_cd", "marketid", "ductcount"]);
  //       setSpanData(spanData);

  //       const fiberCableData = await fetch3GisLayer("https://arcgis.risebroadband.com/arcgis/rest/services/3GIS/Telecom/MapServer/9/query", ["cable_category", "inventory_status_code", "measuredlength", "placementtype", "rbid", "fibercount", "manufacturer", "admincount", "mat_description", "actual_length", "marketid"]);
  //       setFiberCableData(fiberCableData);

  //       // ... (other fetch operations)
  //     } catch (error) {
  //       console.error("An error occurred while fetching data:", error);
  //     }
  //   };

  //   fetchData();
  // }, []);

  const handleGeocoderSearch = (selectedLocation) => {
    const [longitude, latitude] = selectedLocation.geometry.coordinates;
    setViewport({
      ...viewport,
      latitude,
      longitude,
      zoom: 14,
    });
  };

  const columns = [
    { name: 'ID', selector: 'id', sortable: true },
    { name: 'Name', selector: 'name', sortable: true },
    // Add more columns as per your data structure
  ];

  // const onError = (e) => {
  //   console.error("Map error:", e);
  //   alert("An error occurred while loading the map. Please try again later.");
  // };
  
  //Span Layer 
  const spanLayer = new GeoJsonLayer({
  id: 'geojson-layer',
  // data: fetch3GisLayer("https://arcgis.risebroadband.com/arcgis/rest/services/3GIS/Telecom/MapServer/12/query", ["inventory_status_code"]),
  data: spanData,
  pickable: true,
  stroked: true,
  filled: true,
  lineWidthMinPixels: 5,  
  getLineColor: [250, 128, 114],  
  getLineWidth: 5,  
});
// //Fiber Cable Layer
const fibercableLayer = new GeoJsonLayer({
  id: 'geojson-layer',
  // data: fetch3GisLayer("https://arcgis.risebroadband.com/arcgis/rest/services/3GIS/Telecom/MapServer/12/query", ["inventory_status_code"]),
  data: fiberCableData,
  pickable: true,
  stroked: true,
  filled: true,
  lineWidthMinPixels: 5,  
  getLineColor: [255, 165, 0],  
  getLineWidth: 5,  
});
// Address Point Layer
const addressPointLayer = new GeoJsonLayer({
  id: 'geojson-layer',
  data: addressPointData,
  pickable: true,
  pointRadiusMinPixels: 10,
  getFillColor: [255, 0, 0],  // Red
});

// Fiber Equipment Layer
const fiberEquipmentLayer = new GeoJsonLayer({
  id: 'geojson-layer',
  data: fiberEquipmentData,
  pickable: true,
  pointRadiusMinPixels: 10,
  getFillColor: [0, 255, 0],  // Green
});

// // Splice Closure Layer
const spliceclosureLayer = new GeoJsonLayer({
  id: 'geojson-layer',
  data: spliceClosureData,
  pickable: true,
  pointRadiusMinPixels: 10,
  getFillColor: [0, 0, 255],  // Blue
});

// // Structure Layer
const structureLayer = new GeoJsonLayer({
  id: 'geojson-layer',
  data: structureData,
  pickable: true,
  pointRadiusMinPixels: 20,
  getFillColor: [255, 255, 0],  // Yellow
});

// // Building Layer
const buildingLayer = new GeoJsonLayer({
  id: 'geojson-layer',
  data: buildingData,
  pickable: true,
  pointRadiusMinPixels: 10,
  getFillColor: [128, 0, 128],  // Purple
});


  return (
    <>
      <div className="app-container">
        <div className="geocoder-container1">
          <CustomGeocoderTaptool onSearch={handleGeocoderSearch} placeholder="Search location" />
        </div>
        <DeckGL
          initialViewState={viewport}
          controller={true}
          layers={[spanLayer,fibercableLayer,structureLayer,addressPointLayer,buildingLayer,spliceclosureLayer, fiberEquipmentLayer]}
          // spanLayer,fibercableLayer, fiberEquipmentLayer,buildingLayer,, spliceclosureLayer,addressPointLayer,
          // onError={onError}
        >
          <StaticMap
            mapStyle="mapbox://styles/mapbox/satellite-streets-v12"
            mapboxApiAccessToken={MAPBOX_ACCESS_TOKEN}
            // onError={onError}
          />
        </DeckGL>
      </div>
      {editableInfoTable && editableInfoTable.length > 0 ? (
        <DataTable
          keyField='id'
          columns={columns}
          data={editableInfoTable}
          defaultSortField="id"
          pagination
          selectableRows
          paginationPerPage={30}
        />
      ) : (
        <p>No data available for the table.</p>
      )}
    </>
  );
};

export default DetailDesignTool;

