import React, { useState, useEffect } from 'react';
import './AccessLayer.css';
import AppContext from '../Umbrella/Context';

const EditWedgeInputModal = ({ isOpen, onClose, onSave, selectedTower, tower, initialData, isEditing,setWedgeModalOpen, style  }) => {
  const [wedges, setWedges] = useState([{ azimuth: '', beamWidth: '90', sectorName: '', tech: 'Tarana', priority: 'Yes', radiusOverride: '' }]);
  const [showAdvanced, setShowAdvanced] = useState(false);
const [tech, setTech] = useState( "");
  const [sectorName, setSectorName] = useState( "");
  const [azimuth, setAzimuth] = useState("");

  const {handleSaveWedges, clickedWedge} = React.useContext(AppContext);

  useEffect(() => {
    // Initialize beamWidth to 90 when the modal opens
    if (isOpen) {
      setWedges(wedges.map(w => ({ ...w, beamWidth: w.beamWidth || '90' })));
    }
  }, [isOpen]);
  
  useEffect(() => {
    if (clickedWedge) {
      setSectorName(clickedWedge.properties["Sector Name"]);
      setTech(clickedWedge.properties.Tech);
      setAzimuth(clickedWedge.properties.Azimuth);
    }
  }, [clickedWedge]);
  
  // useEffect(() => {
  //   if (clickedWedge && clickedWedge.length > 0) {
  //     const firstWedge = clickedWedge[0];
  //     setSectorName(firstWedge.properties["Sector Name"]);
  //     setTech(firstWedge.properties.Tech);
  //     setAzimuth(firstWedge.properties.Azimuth);
  //   }
  // }, [clickedWedge]);


  const handleAddWedge = () => {
  setWedges([...wedges, { azimuth: '', beamWidth: '90', sectorName: '', tech: 'Tarana', priority: 'Yes', radiusOverride: '' }]);
};
  const handleChange = (index, field, value) => {
    const newWedges = [...wedges];
    newWedges[index][field] = value;
    setWedges(newWedges);

    // Update individual states
    if (field === 'sectorName') setSectorName(value);
    if (field === 'tech') setTech(value);
    if (field === 'azimuth') setAzimuth(value);
  };

  const handleSubmit = () => {
    // Update wedges state with individual states
    const newWedges = [...wedges];
    newWedges[0].sectorName = sectorName;
    newWedges[0].tech = tech;
    newWedges[0].azimuth = azimuth;
    setWedges(newWedges);

    onSave(newWedges);  
    handleSaveWedges(newWedges);  
    onClose();
  };
    return (
     <div className={`modal ${isOpen ? 'is-open' : ''}`} onClick={(e) => e.stopPropagation()}>
      <div className="wedge-input-modal compact">
        <div className="modal-content" style={{ position: 'relative' }}>
          <button className="close-button" onClick={onClose}>X</button>
          {wedges.map((wedge, index) => (
            <div key={index} className={`wedge-input ${showAdvanced ? 'advanced' : ''}`}>
                <div className="input-group">
                  <label className="small-label">Sector Name</label>
                  <input type="text" value={sectorName} onChange={(e) => handleChange(index, 'sectorName', e.target.value)}  readOnly={isEditing} />
                </div>
                <div className="input-group">
                  <label className="small-label">Azimuth</label>
                  <input type="number" value={azimuth} onChange={(e) => handleChange(index, 'azimuth', e.target.value)} />
                </div>
                <div className="input-group">
                  <label className="small-label">Tech</label>
                  <select value={tech} onChange={(e) => handleChange(index, 'tech', e.target.value)}>
                    <option value="Tarana">Tarana</option>
                    <option value="450M">450M</option>
                  </select>
                
             </div>
              {showAdvanced && (
              <div className="advanced-settings">
                
                  <div className="input-group">
                    <label className="small-label">Beam Width</label>
                    <input className="small-input" type="number" value={wedge.beamWidth} onChange={(e) => handleChange(index, 'beamWidth', e.target.value)} />
                  </div>
                  <div className="input-group">
 <label className="small-label">Priority</label>
  <select className="priority-select" value={wedge.priority} onChange={(e) => handleChange(index, 'priority', e.target.value)}>
    <option value="Yes">Yes</option>
    <option value="No">No</option>
  </select>
</div>
                  <div className="input-group">
                    <label className="small-label">Radius Override</label>
                    <input className="small-input" type="number" value={wedge.radiusOverride} onChange={(e) => handleChange(index, 'radiusOverride', e.target.value)} />
                  </div>
                </div>
              )}
            </div>
          ))}
           <div className="button-group">
            <button onClick={handleAddWedge}>+ Add</button>
            <button onClick={handleSubmit}>Save</button> 
            {/*<button onClick={onClose}>Close</button>*/}
         </div>
          <a href="#" className="advanced-link" onClick={() => setShowAdvanced(!showAdvanced)}>
            {showAdvanced ? 'Hide Advanced' : 'Advanced'}
          </a>
        </div>
      </div>
    </div>
  );
};
export default EditWedgeInputModal;

