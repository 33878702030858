import React, { useState, useEffect, useContext } from 'react';
import DeckGL from '@deck.gl/react';
import { StaticMap } from 'react-map-gl';
import { EditableGeoJsonLayer, DrawPolygonMode, ModifyMode, ViewMode, DoubleClickMode, DrawLineStringMode } from 'nebula.gl';
import { Auth } from 'aws-amplify';
import {MVTLayer} from '@deck.gl/geo-layers';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Popover from '@material-ui/core/Popover';
import {GeoJsonLayer} from '@deck.gl/layers';
import ContextProvider from '../Umbrella/ContextProvider';
import AppContext from '../Umbrella/Context';
import { Storage, API } from 'aws-amplify';
import './TextField.css'
import styled from 'styled-components';

const DeckGLComponent = ({
  viewport,
  visibleLayers,
  geocoderContainerRef,
  mapRef,
  MAPBOX_ACCESS_TOKEN,
  onLayerClick
}) => {
  const [data, setData] = useState({
    type: 'FeatureCollection',
    features: [],
  });
  const [selectedFeatureIndexes, setSelectedFeatureIndexes] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [showLayer, setShowLayer] = useState(false); 
  const [token, setToken] = useState('');
  const [popupInfo, setPopupInfo] = useState(null);
  const [isDrawing, setIsDrawing] = useState(false);
   const [anchorEl, setAnchorEl] = React.useState(null);
   const [commitName, setCommitName] = useState("");
  const [showCommitInput, setShowCommitInput] = useState(false);
  const [showRunRond, setShowRunRond] = useState(false); 
  const [showTextBox, setShowTextBox] = useState(true); 
  const [runRond, setRunRond] = useState(false);
  const [exportType, setExportType] = useState({ shapefile: true, csv: true });
  const [isExportButtonDisabled, setIsExportButtonDisabled] = useState(false); 
  const [isChecked, setIsChecked] = useState(false);
  const [selectedPolygon, setSelectedPolygon] = useState(null);
  const [refreshTable, setRefreshTable] = useState(0);
  const [userRole, setUserRole] = useState('newUser');
  const [tooltipInfo, setTooltipInfo] = React.useState({ x: 0, y: 0, object: null });
  const { mode, setMode, editableInfoTable, setEditableInfoTable } = useContext(AppContext);
  const [isMiddleMileDrawing, setIsMiddleMileDrawing] = useState(false);
  const [runMiddleMile, setRunMiddleMile] = useState(false);
  const [drawnFeatures, setDrawnFeatures] = useState([]);
  
  const handleRunMiddleMile = async () => {
  const geoJson = {
    type: "FeatureCollection",
    features: drawnFeatures
  }
  setShowCommitInput(true);
  setShowRunRond(false); // Hide 'Run Rond' button when commit input shows
  setRunMiddleMile(true); // Trigger the useEffect hook for running the middle mile function
};


  
  useEffect(() => {
  Auth.currentAuthenticatedUser({
    bypassCache: true  
  })
  .then(user => {

    const groups = user.signInUserSession.idToken.payload['cognito:groups']; // get user groups from payload
    if (groups.includes('Engineering')) {
      setUserRole('Engineering');
    } else if (groups.includes('Operations')) {
      setUserRole('Operations');
    } else {
      setUserRole('newUser');
    }
  })
  .catch(err => console.log(err));
}, []);
useEffect(() => {
    //console.log(exportType);
}, [exportType]);

useEffect(() => {
  const isAnyExportTypeChecked = Object.values(exportType).some(value => value === true);
  setIsChecked(isAnyExportTypeChecked);
}, [exportType]);

 const handleRunRond  = async (geoJson,latestFeatureIndex,data) => {
      try {
       const response = await API.post("tapestryGeo", "/rond/predict", {
    body: {lasso:geoJson,
            name:commitName
        },
    });
        let result = await response
        result.name = commitName
        if(response) {
        
          const newData = {...data}; 
          newData["features"][latestFeatureIndex]["properties"] = result;  // update with new result
          setData(newData);  // set the new state
        } else {
          throw new Error("Response does not have a 'result' property");
        }
      
         setPopupInfo({
        ...popupInfo,
        content: (
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Predicted Path</TableCell>
                  <TableCell>Predicted HHP</TableCell>
                  <TableCell>Road Miles</TableCell>
                  <TableCell>Address Points</TableCell>
                  <TableCell>Total Cost</TableCell>
                  <TableCell>Cost Per HHP</TableCell>
                  <TableCell>Cost Per Foot</TableCell>
                  <TableCell>Cost Per Mile</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>{result.pred_path}</TableCell>
                  <TableCell>{result.pred_hhp}</TableCell>
                  <TableCell>{result.road_miles}</TableCell>
                  <TableCell>{result.addr_points}</TableCell>
                  <TableCell>{result.total_cost}</TableCell>
                  <TableCell>{result.cost_per_hhp}</TableCell>
                  <TableCell>{result.cost_per_foot}</TableCell>
                  <TableCell>{result.cost_per_mile}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          <Button variant="contained" color="primary" disabled={isExportButtonDisabled} onClick={handleExportAddresses}>Export Addresses</Button>
          </TableContainer>
        ),
      });
        } catch (error) {
          console.error("Error:", error);
           alert("Run Rond function failed. Please try again.");
        }
        setShowTextBox(false);
        setCommitName("");
        setRunRond(false)
      };
      
 useEffect(() => {
  if(runRond){
    const geoJson = {
      type: "featureCollection",
      features: data.features[selectedFeatureIndexes]
    }
      handleRunRond(geoJson,selectedFeatureIndexes,data)
    }
}, [runRond, selectedFeatureIndexes, exportType, isChecked]);

    
useEffect(() => {
  if(runMiddleMile){
    console.log(selectedFeatureIndexes)
    const geoJson = {
      type: "featureCollection",
      features: data.features[selectedFeatureIndexes]
    }
    
    console.log(geoJson)
const handleRunMiddleMile  = async (geoJson,latestFeatureIndex,data) => {
      try {
       const response = await API.post("tapestryGeo", "/rond/middlemile", {
    body: {geom:geoJson,
            name:commitName
        },
    });
        let result = await response
        result.name = commitName
        if(response) {
        
          const newData = {...data}; 
          newData["features"][latestFeatureIndex]["properties"] = result;  // update with new result
          setData(newData);  // set the new state
        } else {
          throw new Error("Response does not have a 'result' property");
        }
      
         setPopupInfo({
        ...popupInfo,
        content: (
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Path Miles</TableCell>
                  <TableCell>Total Cost</TableCell>
                  <TableCell>Cost Per Foot</TableCell>
                  <TableCell>Cost Per Mile</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>{result.path_miles}</TableCell>
                  <TableCell>{result.total_cost}</TableCell>
                  <TableCell>{result.cost_per_foot}</TableCell>
                  <TableCell>{result.cost_per_mile}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        ),
      });
        } catch (error) {
          console.error("Error:", error);
          alert("Run Middle Mile function failed. Please try again.");
        }
        setShowTextBox(false);
        setCommitName("");
        setRunMiddleMile(false)
      };
      handleRunMiddleMile(geoJson,selectedFeatureIndexes,data)
    }
}, [runMiddleMile, selectedFeatureIndexes, exportType, isChecked]);


// useEffect(() => {
//   // Fetch data and set in state
// }, [refreshTable]);

useEffect(() => {
  // console.log("commitName has changed", commitName);
}, [commitName]);

useEffect(() => {
    setIsExportButtonDisabled(!(exportType.shapefile || exportType.csv)); // update button disable state when exportType changes
  }, [exportType]);

  useEffect(() => {
    if (showLayer) {
      fetch('https://www.arcgis.com/sharing/generateToken?f=json&username=estoelzel_rb&password=FiojBL8vRQ87kT&referer=rise.arcgis.com&client=referer') 
        .then(response => response.json())
        .then(data => setToken(data.token))
        // .then(console.log("AccessToken",token));
    }
  }, [showLayer]);

const editableGeoJsonLayer = new EditableGeoJsonLayer({
  id: 'geojson-layer',
  data,
  mode,
  selectedFeatureIndexes,
  pickable: true,
  onClick: info => handlePolygonClick(info),
  onEdit: ({ editType, updatedData, featureIndexes }) => {
    if (editType === 'addFeature' && updatedData.features.length > 0) {
      // Add the new feature to the drawnFeatures array
      setDrawnFeatures([...drawnFeatures, updatedData.features[updatedData.features.length - 1]]);
      
      const newFeatureIndexes = [updatedData.features.length - 1];
      setSelectedFeatureIndexes(newFeatureIndexes);
      updatedData.features[updatedData.features.length - 1].properties = { status: 'Pending...' };
      setData(updatedData);
      setIsDrawing(false);
      setPopupInfo({
        position: updatedData.features[0].geometry.coordinates[0][0],
        content: (
            <div>
              <button 
                onClick={() => {
                  setRunRond(true);
                }}
                disabled={isMiddleMileDrawing} // Disable this button when isMiddleMileDrawing is true
              >
                Run Rond
              </button>
              <button 
                onClick={() => {
                  handleRunMiddleMile();
                }}
                disabled={isDrawing} // Disable this button when isDrawing is true
              >
                Run Middle Mile
              </button>
            </div>

        )
      });       
      setAnchorEl(document.getElementById('deckgl-wrapper')); 
      setShowTextBox(true);
      setMode(new ViewMode());
    }
    
    if (editType.includes("Position")){
      setData(updatedData);
    }
  }
});

 function handleFocus(event) {
  event.target.select();
}
  const mvtLayer = showLayer ? new MVTLayer({
    id: 'mvt-layer',
    data: `https://tiles.arcgis.com/tiles/dafStHI77KWvpW34/arcgis/rest/services/Address_Points/VectorTileServer/tile/{z}/{y}/{x}.pbf?token=${token}`, // Include the token in the URL 
    // below is dev server
    // data: 'https://34.217.151.4:3000/census_blocks_2020/{z}/{x}/{y}',
    // below is aws
    //data:'http://54.68.105.23:3000/census_blocks_2020/{z}/{x}/{y}',
    // /tapestry_hu
    //Census Block 
    // data: 'https://martin.rbtapestrytools.com:3000/census_blocks_2020/{z}/{x}/{y}',
    //buildingpolygon
    // data: 'https://martin.rbtapestrytools.com:3000/tapestry_hu/{z}/{x}/{y}',
    pickable: true,
    // properties for points visualization
      getFillColor: [160, 160, 180, 200], // color of points
      getRadius: 5, // size of points
      pointRadiusUnits: 'pixels', // or 'meters' if you want to display the point radius in meters
      onDataLoad: () => {},
    }) : null;
    const mvtLayerRoads = showLayer ? new MVTLayer({
    id: 'mvt-layer-roads',
    data: `https://tiles.arcgis.com/tiles/dafStHI77KWvpW34/arcgis/rest/services/Roads_Vector_Tiles/VectorTileServer/tile/{z}/{y}/{x}.pbf?token=${token}`, // Include the token in the URL
    pickable: true,
      stroked: true,
      filled: false,
      lineWidthMinPixels: 1,
      getLineColor: [128, 0, 0, 150],
      getLineWidth: 1,
      onDataLoad: () => {},
    }) : null;
    
 const drawPolygon = () => {
  setIsDrawing(true);  // Start drawing
  setEditMode(false);
  setMode(new DrawPolygonMode());
  setSelectedFeatureIndexes([]);
};

// const handleRunMiddleMile = async () => {
//   setShowCommitInput(true);
//   setShowRunRond(false); // Hide 'Run Rond' button when commit input shows
// };
const toggleEditMode = () => {
  if (editMode) {
    setMode(new ViewMode());

    // Add the popup when you click 'Stop Editing'
    setPopupInfo({
      position: data.features[0]?.geometry?.coordinates[0][0],
      content: (
       <div>
            <button 
              onClick={() => {
                setRunRond(true);
              }}
              disabled={isMiddleMileDrawing} // Disable this button when isMiddleMileDrawing is true
            >
              Run Rond
            </button>
            <button 
              onClick={() => {
                handleRunMiddleMile();
              }}
              disabled={isDrawing} // Disable this button when isDrawing is true
            >
              Run Middle Mile
            </button>
          </div>

      )
    });
    setAnchorEl(document.getElementById('deckgl-wrapper')); 
    setShowTextBox(true);
  } else {
    setMode(new ModifyMode());
  }
  setEditMode(prev => !prev);
};
 const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const handleChange = (event) => {
  setIsChecked(event.target.checked);
};
function CustomCheckbox({ label, checked, onChange, name }) {
  return (
    <label style={{ display: 'flex', alignItems: 'center' }}>
      <input type="checkbox" checked={checked} onChange={onChange} name={name} />
      {label}
    </label>
  );
}
 const handleCommitRond = async () => {
    setShowCommitInput(true);
    setShowRunRond(false); // Hide 'Run Rond' button when commit input shows
  };
const handleExportAddresses = async () => {
  
  const params = {"shapefile":"False","csv":"False"}
  
  if (exportType.csv) {
    params["csv"] = "True";
  } 
  
  if (exportType.shapefile) {
    params["shapefile"] = "True";
  } 
  
  const geoJson = {
        type: "featureCollection",
        features: data.features[data.features.length - 1]
      }

  try {
    const downloadKey = await API.post("tapestryGeo",'/csvExport/address', {
        queryStringParameters: params,
        body:geoJson
    })
    
    const fileName = downloadKey.split("/")[1];
    const url = await Storage.get(downloadKey)
    
    const link = document.createElement("a")
    link.download = fileName
    link.href = url
    link.click()
  } catch (error) {
    console.error("Error:", error);
  }
};
const handleCommitNameChange = (event) => {
  setCommitName(event.target.value);
  
};

const handleCheckboxChange = (event) => {
  setExportType({ ...exportType, [event.target.name]: event.target.checked });
};
const handlePolygonClick = info => {
  // console.log("HandlePolygonClick",info)
  if (info.object && info.object.properties.guideType !== "tentative") {
    if (info.object.geometry.type === 'Polygon' || info.object.geometry.type === 'LineString') {
    // console.log("HandlePolygonClick",info)
    //setSelectedPolygon(info.object);
    setSelectedFeatureIndexes([info.index])
    }
  }
};
  
return (
  <div>
    {userRole === 'Engineering' && (
      <div style={{ display: 'flex', position: 'absolute', bottom: '20px', left: '20px', zIndex: 1000 }}>
        <Button 
          variant="contained" 
          color="primary" 
          onClick={() => {
            if (isDrawing) {
              setMode(new ViewMode());  // Switch back to ViewMode
            } else {
              drawPolygon(); // Start drawing
            }
            setIsDrawing(!isDrawing); // Toggle the drawing state
          }} 
          style={{ marginRight: '10px' }}
        >
          {isDrawing ? 'Stop Lasso' : 'ROND Lasso'}
        </Button>

       <Button 
          variant="contained" 
          color="primary" 
          onClick={() => {
            if (isMiddleMileDrawing) {
              setMode(new ViewMode());  // Switch back to ViewMode
            } else {
              setMode(new DrawLineStringMode()); // Start drawing line string
            }
            setIsMiddleMileDrawing(!isMiddleMileDrawing); // Toggle the drawing state
          }} 
          style={{ marginRight: '10px' }}
        >
          {isMiddleMileDrawing ? 'Stop Drawing' : 'Draw Middle Mile'}
        </Button>

        <Button variant="contained" color="secondary" onClick={toggleEditMode} style={{ marginRight: '10px' }}>
          {mode instanceof ViewMode ? 'Edit Lasso' : 'Stop Editing'}
        </Button>

        <Button variant="contained" color="default" onClick={() => setShowLayer(!showLayer)}>
          {showLayer ? 'Hide Address/Road Data' : 'Show Address/Road Data'}
        </Button>
      </div>
    )}

    <DeckGL
      initialViewState={viewport}
      controller={{
        doubleClickZoom: false,
      }}
      layers={[...visibleLayers, mvtLayer, mvtLayerRoads, editableGeoJsonLayer].filter(Boolean)}
      ref={geocoderContainerRef}
      onBeforeRender={({ gl }) => {
        gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT);
      }}
      getTooltip={({object}) => object && Object.entries(object.properties).map(([key, value]) => `${key}: ${value}`).join('\n')}
      onClick={onLayerClick}
    >
      <StaticMap
        mapStyle="mapbox://styles/mapbox/satellite-streets-v12"
        mapboxApiAccessToken={MAPBOX_ACCESS_TOKEN}
        ref={mapRef}
      ></StaticMap>
      
      {popupInfo && !isDrawing && (
        <div>
          <Button aria-describedby={id} variant="contained" onClick={handleClick}>
            Open Popover
          </Button>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
          >
            <div style={{ display: 'absolute', alignItems: 'center', bottom: '20px' }}>
              {popupInfo.content}
              <div zIndex={1003}>
                {showTextBox && (
                  <input
                    type="text"
                    id="commit-name"
                    placeholder="Commit Name"
                    value={commitName}
                    onChange={handleCommitNameChange}
                    autoFocus
                    onFocus={handleFocus}
                    className="text-field"
                  />
                )}
              </div>
            </div>
          </Popover>
        </div>
      )}
    </DeckGL>
   </div>
);
};

export default DeckGLComponent;